import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import '../../Styles/Button.scss';
import Button from '@material-ui/core/Button';

const styles = theme => ({
	button: {
		margin: theme.spacing.unit,
	},
	input: {
		display: 'none',
	},
});

function OutlinedButtons(props) {
	return (
		<div>
			<Button variant="outlined" className='buttonStyle' onClick={props.onClick}>
				Submit
			</Button>
		</div>
	);
}

export default withStyles(styles)(OutlinedButtons);
