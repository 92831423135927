import React, { Component } from 'react';
import '../Styles/Heatmap.scss';
import HeatmapInfoPanel from './HeatmapInfoPanel';
import HeatmapTeam from './HeatmapTeam';
import StepSlider from './StepSlider';

class Heatmap extends Component {
	state = {
		heatmap: null,
		isCellOpen: false,
		heatmapData: null,
		currentTeam: null,
		currentPractice: null,
		currentTrend: null,
		currentScore: null,
		mousePos: null,
		modalTeam: null,
		scoreDatesData: null
	}

	componentDidMount() {
		if(this.props.scoreDatesData) {
			this.setState({
				value: this.props.scoreDatesData.length - 1
			})
		} 
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!prevProps.scoreDatesData && this.props.scoreDatesData) {
			this.setState({
				value: this.props.scoreDatesData.length - 1
			})
		}
	}

	isEmpty = (obj) => {
		for (var key in obj) {
			if (hasOwnProperty.call(obj, key)) return false;
		}
		return true;
	}

	setCurrentTeam = (teamName) => {
		this.setState ({
			currentTeam:teamName,
		})
	}

	setModalTeam = (modalTeam) => {
		this.setState({
			modalTeam: modalTeam
		});
	}

	getTeamDataByName = (data, teamName) => {
		if (data != null) {
			for(let i = 0; i < data.length; i++) {
				if (data[i].name === teamName) {
					return data[i];
				}
			}
		}
		return {};
	}

	generatePracticeGroupData(data) {
		data.forEach((team) => {
			let practiceGroups = [];
			let practices = team.practices.length > 1 ? team.practices : this.props.practices;
			practices.forEach((practice) => {
				let pgid = practice.practice_group_id - 1;
				if (!practiceGroups[pgid]) {
					practiceGroups[pgid] = {
						id: pgid,
						name: practice.practice_group_name,
						practices: [],
						scores: [],
						insights: []
					}
				}
				practiceGroups[pgid].practices.push(practice);
			});
			practiceGroups.forEach((practiceGroup) => {
				if (practiceGroup.practices[0].scores) {
					for (let i = 0; i < practiceGroup.practices[0].scores.length; i++) {
						let total = 0;
						for (let j = 0; j < practiceGroup.practices.length; j++) {
							if (practiceGroup.practices[j].scores[i]) {
								total += practiceGroup.practices[j].scores[i].score;
							}
						}
						let score = {
							score: total/practiceGroup.practices.length,
							date: practiceGroup.practices[0].scores[i].score_date
						}
						practiceGroup["scores"].push(score);
						practiceGroup["insights"].push(practiceGroup.practices[0].practice_group_insights[i]);
					}
				}
			});
			team["practiceGroups"] = practiceGroups;
		})
	}

	handleSliderChange = (value) => {
		this.props.handleDateSlider(value);
	}

	render() {
		let heatmapData = [];
		let apiData = this.props.data;
		let linkData = null;
		if (this.props.history != null) {
			linkData = this.props.history.location.state.data;
		}
		if (apiData != null) {
			//render heatmap data given by sheets ranges
			heatmapData = this.props.getTeamsFromOrganisation();
		} else if (linkData != null) {
			//render heatmap data given by going back from team view
			heatmapData = linkData;
		}
		let scoreDatesData = this.props.scoreDatesData ? this.props.scoreDatesData : [''];
		this.generatePracticeGroupData(heatmapData);
		return (
			<div className="heatmap" ref={"heatmap"}>
				<HeatmapInfoPanel
					data={this.props.data}
					scoreColorClassNames={this.props.scoreColorClassNames}
					teamData={this.getTeamDataByName(this.props.data, this.state.modalTeam)}
					scoreDatesData={this.props.scoreDatesData}
					dateSliderValue={this.props.dateSliderValue}
					isEmpty={this.isEmpty}
					modalTeam={this.state.modalTeam}
					setModalTeam={this.setModalTeam}
					setCurrentTeam={this.setCurrentTeam}
					refs={this.refs} />
				{heatmapData.map((team, i)=>{
					let teamNameLC = team.name?team.name.toLowerCase():'';
					let sp = this.props.searchParams;
					let searchParamLC = sp?sp.toLowerCase():null;
					let isSelected = (this.state.currentTeam === team.name && this.state.currentTeam !== null)
									|| (teamNameLC.includes(searchParamLC) && sp != null);
					let isNotSelected = (this.state.currentTeam !== team.name && this.state.currentTeam !== null)
									|| (!teamNameLC.includes(searchParamLC) && sp != null );
					return (
						<div className="heatmap-team-column" key={team.name+i} ref={team.name}>
							<HeatmapTeam
								scoreColorClassNames={this.props.scoreColorClassNames}
								searchParams={this.props.searchParams}
								isSelected={isSelected}
								isNotSelected={isNotSelected}
								data={this.props.data}
								scoreDatesData={this.props.scoreDatesData}
								dateSliderValue={this.props.dateSliderValue}
								team={team}
								currentTeam={this.state.currentTeam}
								setModalTeam={this.setModalTeam}
								setCurrentTeam={this.setCurrentTeam}
								teamIndex={i}
								/>
						</div>
					);
				})}
				<div className="heatmap__dateSlider">
					<StepSlider
						value={this.props.dateSliderValue}
						min={0}
						max={ scoreDatesData?scoreDatesData.length - 1:0 }
						step={1}
						handleOnChange={this.handleSliderChange}
						scoreColor="#FFF"
					/>
				</div>
				<div className="heatmap__date">
					{ scoreDatesData[this.props.dateSliderValue]?scoreDatesData[this.props.dateSliderValue].split(" ")[0] : "" }
				</div>
			</div>
		);
	}
}

export default Heatmap;
