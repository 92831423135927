import React, { Component } from 'react';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import MdExpandMore from 'react-icons/lib/md/expand-more';
import MdChevronRight from 'react-icons/lib/md/chevron-right';
import { authAxios } from './Auth';

import '../Styles/HealthCheckForm.scss';
import '../Styles/vars.scss';
import { apiHost } from '../config';
import Navbar from './Navbar';
import DataEntrySlider from './FormComponents/DataEntrySlider';
import DataEntryYesNo from './FormComponents/DataEntryYesNo';
import Button from './FormComponents/Button';
import DataEntryTextField from './FormComponents/DataEntryTextField';
import Picker from './FormComponents/Picker';
import DatePicker from './FormComponents/DatePicker';

class HealthCheckForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isSubmitted: false,
			teamsRange: [],
			practiceScores: {},
			practiceGroups: [],
			practiceGroupInsights: {},
			visibleList: {},
			teamId: this.props.location.state ? this.props.location.state.teamId : '',
			teamName: null,
			date: moment().format('YYYY-MM-DD'),
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		if (this.props.data) {
			this.populateTeamsArray();
		}
		this.setupDefaultFormData();
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.data && this.props.data) {
			this.populateTeamsArray();
		}
	}

	populateTeamsArray = () => {
		const { data } = this.props;

		const teams = data.map(team => ({
			value: team.id,
			label: team.name,
		}));

		this.setState({
			teamsRange: teams,
		});
	};

	setupDefaultFormData = () => {
		const _practiceScores = {};
		const practices = this.props.practices ? this.props.practices : [];
		// loop through a set of all practices
		practices.forEach(practice => {
			// extract practice group id
			const practiceScore = {};
			practiceScore.practice_id = practice.id;
			practiceScore.practice = practice.display_name;
			practiceScore.score = 0;
			// practiceScore["score_date"] = score_date;
			_practiceScores[practice.id] = practiceScore;
		});
		this.setState({
			practiceScores: _practiceScores,
		});
	};

	setTeamAndDateForScores = (teamId, date) => {
		const _practiceScores = this.state.practiceScores;
		for (const key in _practiceScores) {
			if (_practiceScores.hasOwnProperty(key)) {
				_practiceScores[key].team_id = teamId;
				_practiceScores[key].score_date = date;
			}
		}
		this.setState({
			practiceScores: _practiceScores,
		});
	};

	readScores = (practice_id, practice, score, score_date) => {
		const _practiceScores = this.state.practiceScores;
		const practiceScore = {};
		practiceScore.team_id = this.state.teamId;
		practiceScore.practice_id = practice_id;
		practiceScore.practice = practice;
		practiceScore.score = score;

		_practiceScores[practice_id] = practiceScore;
		this.setState({
			practiceScores: _practiceScores,
		});
	};

	// If will set visibility based on second param. If no second param is passed, will toggle from last value
	toggleVisible = (current, show) => {
		const _visibleList = this.state.visibleList;

		// If 'show' wasn't passed, toggle visibility
		if (show === undefined || null) {
			_visibleList[current] = !_visibleList[current];
		} else {
			_visibleList[current] = show;
		}
		this.setState({
			visibleList: _visibleList,
		});
	};

	submitForm = e => {
		e.preventDefault();
		this.setTeamAndDateForScores(this.state.teamId, this.state.date);

		const scoreApi = `${apiHost}/api/scores`;
		authAxios
			.post(scoreApi, this.state.practiceScores)
			.then(res => {
				if (res.status === 200) {
					this.submitInsights();
				} else {
					console.log(res);
				}
			})
			.catch(err => {
				console.log(err);
			});
	};

	submitInsights = () => {
		const insightapi = `${apiHost}/api/insights`;
		authAxios
			.post(insightapi, this.state.practiceGroupInsights)
			.then(res => {
				if (res.status === 201 || res.status === 200) {
					this.setState({
						isSubmitted: true,
					});
				} else {
					console.log(res);
				}
			})
			.catch(err => {
				console.log(err);
			});
	};

	updateFormData = (name, value) => {
		this.setState({
			[name]: value,
		});
		if (name === 'teamId') {
			this.setState({
				teamId: value,
			});
			this.getTeamNameById(value);
			this.setTeamPracticesWithId(value);
		}
	};

	updateInsights = (name, id, value) => {
		const _practiseGroupInsights = this.state.practiceGroupInsights;
		_practiseGroupInsights[name] = {
			team_id: this.state.teamId,
			practice_group_id: id,
			insight: value,
			insight_date: this.state.date,
		};
		this.setState({
			practiceGroupInsights: _practiseGroupInsights,
		});
	};

	setTeamPracticesWithId = id => {
		const { data, practices } = this.props;
		let teamPractices;

		data.forEach(team => {
			if (team.id === id) {
				if (team.practices.length > 1) {
					teamPractices = team.practices;
				}
			}
		});

		const practiceGroups = [];
		practices.forEach(practice => {
			const practiceGroupId = practice.practice_group_id;
			const practiceGroupIndex = this.practiceGroupIndex(practiceGroups, practiceGroupId);
			if (practiceGroupIndex === -1) {
				const practiceGroup = {
					id: practiceGroupId,
					name: practice.practice_group_name,
					practices: [],
				};
				practiceGroup.practices.push(practice);
				practiceGroups.push(practiceGroup);
			} else {
				practiceGroups[practiceGroupIndex].practices.push(practice);
			}
		});
		this.setUpFormDataObject(teamPractices);
		this.setState(
			{
				practiceGroups,
			},
			() => {
				this.toggleVisible(this.state.practiceGroups[0].name, true);
			}
		);
	};

	practiceGroupIndex = (practiceGroupArr, id) => {
		let index = -1;
		practiceGroupArr.forEach((practiceGroup, i) => {
			if (practiceGroup.id === id) {
				index = i;
			}
		});
		return index;
	};

	setUpFormDataObject = practices => {
		const _practiceScores = {};
		if (practices) {
			// loop through a set of all practices
			practices.forEach(practice => {
				// extract practice group id
				const practiceScore = {};
				practiceScore.practice_id = practice.practice_id;
				practiceScore.practice = practice.display_name;
				practiceScore.score = practice.scores[practice.scores.length - 1].score;
				// practiceScore["score_date"] = score_date;
				_practiceScores[practice.practice_id] = practiceScore;
			});
			const mergedScores = { ...this.state.practiceScores, ..._practiceScores };
			this.setState({
				practiceScores: mergedScores,
			});
		}
	};

	getTeamNameById = id => {
		const api = `${apiHost}/api/teams/${id}`;
		authAxios
			.get(api)
			.then(res => {
				if (res.status === 200) {
					this.setState({
						teamName: res.data.data.name,
					});
					return Promise.resolve(res.data.data.name);
				}
			})
			.catch(err => {
				console.log(err);
				return Promise.reject(err);
			});
	};

	render() {
		if (this.state.isSubmitted) {
			return (
				<Redirect
					to={{
						pathname: '/',
						state: {
							prevPage: 'healthCheckForm',
						},
					}}
				/>
			);
		}
		let teamNameView;

		if (this.props.location.state.prevPage === '/teamForm') {
			teamNameView = this.state.teamName;
		} else {
			teamNameView = (
				<div className="team-name">
					<Picker name="teamId" updateFormData={this.updateFormData} range={this.state.teamsRange} variant="outlined" />
				</div>
			);
		}

		/**
		 * Vanilla helper function to return the correct question type element. This was extracted
		 * from the return statement below and replaced with a call to this function.
		 * I'm not sure what the correct way to do this is in the React workflow, but it's the
		 * first idea that came to mind that would sufficiently get the job done.
		 * @param type          The numeric variable of the question type
		 * @param baseClass     The base class (equivalent of "this").
		 * @param practiceObj   The practice object (equivalent of "practice").
		 * @author Byron
		 */
		function getFormComponent(type, baseClass, practiceObj) {
			switch (type) {
				case 0:
					return (
						<DataEntrySlider
							scoreColor={baseClass.props.scoreColor}
							scoreDescription={baseClass.props.scoreDescription}
							practice={practiceObj}
							practiceScore={baseClass.state.practiceScore}
							prefillValue={baseClass.state.practiceScores[practiceObj.id].score}
							readScores={baseClass.readScores}
							submitForm={baseClass.submitForm}
						/>
					);
				case 1:
					return (
						<DataEntryYesNo
							scoreColor={baseClass.props.scoreColor}
							scoreDescription={baseClass.props.scoreDescription}
							practice={practiceObj}
							practiceScore={baseClass.state.practiceScore}
							prefillValue={baseClass.state.practiceScores[practiceObj.id].score}
							readScores={baseClass.readScores}
							submitForm={baseClass.submitForm}
						/>
					);
				default:
					return null;
			}
		}

		return (
			<div className="health-check-form">
				<Navbar isNewUser={this.props.isNewUser} user={this.props.user} pageName={this.props.pageName} />

				<div className="health-form-title">Entering data for</div>

				<div className="name-date">
					<div className="team-name-style">
						<span className="team-name-header">{teamNameView}</span> <span className="on-the">on the</span>
					</div>
					<DatePicker
						className="DatePicker"
						name="date"
						updateFormData={this.updateFormData}
						defaultDate={this.state.date}
					/>
				</div>

				<div className="row">
					<div className="legend column">
						<div className="legend-description">
							<div className="legend-key score-colors__self-blocking">-1</div>

							<div className="legend-description-copy">Self-blocking</div>
						</div>

						<div className="legend-description">
							<div className="legend-key score-colors__not-yet-checked">0</div>

							<div className="legend-description-copy">Not yet checked</div>
						</div>

						<div className="legend-description">
							<div className="legend-key score-colors__practice-not-evident">1</div>

							<div className="legend-description-copy">Practice not evident</div>
						</div>
					</div>

					<div className="legend column">
						<div className="legend-description ">
							<div className="legend-key score-colors__inconsistent-practice">2</div>

							<div className="legend-description-copy">Inconsistent practice</div>
						</div>

						<div className="legend-description">
							<div className="legend-key score-colors__consistent-practice">3</div>

							<div className="legend-description-copy">Consistent practice</div>
						</div>

						<div className="legend-description">
							<div className="legend-key score-colors__effective-practice">4</div>

							<div className="legend-description-copy">Effective practice</div>
						</div>
					</div>
				</div>

				<div className="instruction">Use slider to select rating</div>

				{this.state.practiceGroups &&
					this.state.practiceGroups.map(practiceGroup => {
						const isVisible = this.state.visibleList[practiceGroup.name];
						return (
							<div key={practiceGroup.id}>
								{/* === GROUP LOOP === */}
								<div className="group-name-header">
									<div className="practice_group_name">{practiceGroup.name}</div>
									<div onClick={e => this.toggleVisible(practiceGroup.name)}>
										<div className="practicegroup-expand">{isVisible ? <MdExpandMore /> : <MdChevronRight />}</div>
									</div>
								</div>
								{isVisible &&
									practiceGroup.practices.map(practice => (
										<div key={practice.id}>
											{/* <p><em>Question type {practice.question_type}</em><p> */
											getFormComponent(practice.question_type, this, practice)}
										</div>
									))}
								{isVisible && (
									<div>
										<div className="annotations-copy">Main contributing factors to these changes:</div>
										<div>
											<DataEntryTextField
												id={practiceGroup.id}
												name={practiceGroup.name}
												updateInsights={this.updateInsights}
											/>
										</div>
									</div>
								)}
							</div>
						);
					})}

				<div className="annotations-field">
					<div className="annotations-field-copy">Describe why you think the scores moved overall:</div>
					<DataEntryTextField />
				</div>

				<div>
					<Button onClick={this.submitForm} />
				</div>
			</div>
		);
	}
}

export default HealthCheckForm;
