import React, { Component } from 'react';
import '../Styles/InfoPanel.scss';
import classNames from 'classnames';
import MdClose from 'react-icons/lib/md/close';
import trendarrow from '../Assets/Img/trendarrow.svg';
import ScoreBlock from './ScoreBlock';

class InfoPanel extends Component {
	handleOnMouseOut = event => {
		this.props.handleOnMouseOut();
	};

	calculateTrend(currentScore, previousScore) {
		let trend = 'Unchanged';
		if (currentScore > previousScore && previousScore !== null) {
			trend = 'Improved';
		} else if (currentScore < previousScore) {
			trend = 'Regressed';
		}
		return trend;
	}

	render() {
		const { averageScore, previousAverageScore } = this.props;
		let trend = this.calculateTrend(averageScore, previousAverageScore);
		return (
			<React.Fragment>
				<div className={classNames('info-panel')}>
					<div className="info-panel_header">
						<div className="primary">
							<div className="info-panel-team">
								{/* <p className="info-panel__team-name-header">TEAM NAME</p> */}
								<h2 className="info-panel__team-name">{this.props.teamData && this.props.teamData.name}</h2>
								<h2 className="info-panel__practice-group">{this.props.practiceGroupName}</h2>
							</div>

							<div className="info-panel-actions">
								{/* <div className="info-panel-button">
								<Link
								to={{
									pathname: '/healthCheckForm',
									state: {
										teamId: this.props.teamData && this.props.teamData.id,
										prevPage: '/teamForm',
									},
								}}
								>
								<div className="update_team">Update Team ></div>
								</Link>
							</div> */}
							</div>
							<div className="health">
								<ScoreBlock
									score={this.props.averageScore}
									scoreColorClassNames={this.props.scoreColorClassNames}
									trendarrow={trendarrow}
									overall={true}
									trend = {trend}
								/>
								<div className="trend">
									<h3>Overall Health</h3>
									<img 
										className={classNames(
											"trend-arrow",
											{'info-panel__trend-arrow__none': averageScore === previousAverageScore || previousAverageScore === null},
											{'info-panel__trend-arrow__up': averageScore > previousAverageScore && previousAverageScore !== null},
											{'info-panel__trend-arrow__down':  averageScore < previousAverageScore && previousAverageScore !== null},
										)} 
										src={trendarrow} 
										alt="trend arrow" />
									<p>{this.calculateTrend(averageScore, previousAverageScore)}</p>
								</div>
							</div>
						</div>
						<div className="info-panel-close">
							<MdClose className="md-close" onClick={this.handleOnMouseOut} />
						</div>
					</div>
					<div className="info-panel__box">{this.props.children}</div>
				</div>
				<div className={classNames('black-layer')} onClick={this.handleOnMouseOut}></div>
			</React.Fragment>
		);
	}
}

export default InfoPanel;
