import React, { Component } from 'react';
import './App.css';
import classNames from 'classnames';
import ReactGA from 'react-ga';

import { apiHost } from './config';
import Main from './Main';
import Footer from './Components/Footer';

import { getProfile, loggedIn, authAxios } from './Components/Auth';
import { UserContext } from './Components/UserContext';

class App extends Component {
	constructor(props) {
		super(props);
		this.initializeReactGA();
	}

	state = {
		data: null,
		scoreDatesData: null,
		practices: null,
		organisation: {
			id: null,
			name: null,
		},
		dateSliderValue: 0,
	};

	componentWillMount() {
		if (loggedIn()) {
			const { id, role_id, user_name, email_address } = getProfile();
			this.context.setUser({
				id,
				role_id,
				user_name,
				email_address,
			});
		}
	}

	componentDidUpdate() {
		const { user } = this.context;
		if (!this.state.data && user) {
			this.initiateDataGather(user);
			this.initiatePractices();
		}
	}

	initializeReactGA = () => {
		ReactGA.initialize('UA-135388105-1');
		ReactGA.pageview('/');
	};

	setData = data => {
		const _scoreDatesData = this.scoreDatesData(data);
		this.setState({
			data,
			scoreDatesData: _scoreDatesData,
			dateSliderValue: _scoreDatesData.length - 1,
		});
	};

	scoreDatesData = data => {
		const dates = [];
		data.forEach(team => {
			const { scores } = team.practices[0];
			scores.forEach(score => {
				if (score.score_date && !dates.includes(score.score_date)) {
					dates.push(score.score_date);
				}
			});
		});
		// Order dates array
		dates.sort(function(a, b) {
			// Turn strings into dates,  then subtract them
			// to get a value that is either negative, positive, or zero.
			return new Date(a) - new Date(b);
		});
		return dates;
	};

	handleDateSlider = value => {
		this.setState({
			dateSliderValue: value,
		});
	};

	scoreDescription = score => {
		if (score < 0) {
			return 'Self-blocking';
		}
		if (score === 0) {
			return 'Not yet checked';
		}
		if (score > 0 && score <= 1) {
			return 'Practice not evident';
		}
		if (score > 1 && score <= 2) {
			return 'Inconsistent practice';
		}
		if (score > 2 && score < 4) {
			return 'Consistent practice';
		}
		if (score === 4) {
			return 'Effective practice';
		}
		return '';
	};

	scoreColorClassNames = (className, score) =>
		classNames(
			className,
			{ 'score-colors__self-blocking': score < 0 },
			{ 'score-colors__not-yet-checked': score === 0 },
			{ 'score-colors__practice-not-evident': score > 0 && score <= 1 },
			{ 'score-colors__inconsistent-practice': score > 1 && score <= 2 },
			{ 'score-colors__consistent-practice': score > 2 && score < 4 },
			{ 'score-colors__effective-practice': score === 4 }
		);

	scoreColor = score => {
		switch (score) {
			case -1:
				return '#e84226';
			case 0:
				return '#606060';
			case 1:
				return '#f49c9a';
			case 2:
				return '#b8e689';
			case 3:
				return '#00d146';
			case 4:
				return '#0072CA';
			default:
				return '#000';
		}
	};

	initiateDataGather = user => {
		const api = `${apiHost}/api/users/${user.id}/scores`;
		authAxios
			.get(api)
			.then(res => {
				if (res.status === 200) {
					this.prepareData(res.data);
				}
			})
			.catch(err => {
				console.log(err);
			});
	};

	initiatePractices() {
		const api = `${apiHost}/api/practices`;
		authAxios
			.get(api)
			.then(res => {
				if (res.status === 200) {
					this.setState({
						practices: res.data.data,
					});
				}
			})
			.catch(err => {
				console.log(err);
			});
	}

	initiateDataGatherWithUserId(userId) {
		const api = `${apiHost}/api/users/${userId}`;
		authAxios
			.get(api)
			.then(res => {
				if (res.status === 200) {
					const user = res.data.data;
					this.context.setUser(user);
					this.initiateDataGather(user);
				}
			})
			.catch(err => {
				console.log(err);
			});
	}

	// Need to be this format to bind this
	prepareData = data => {
		if (data) {
			const _data = [];
			for (const key in data) {
				const teamData = data[key];
				_data.push(teamData);
			}
			this.setData(_data);
		}
	};

	rnd9Char() {
		return Math.random()
			.toString(29)
			.substr(2, 9);
	}

	isNewUser = () => this.state.data && this.state.data.length === 0;

	render() {
		return (
			<div className="App">
				<Main
					organisation={this.state.organisation}
					data={this.state.data}
					scoreDatesData={this.state.scoreDatesData}
					dateSliderValue={this.state.dateSliderValue}
					handleDateSlider={this.handleDateSlider}
					practices={this.state.practices}
					scoreDescription={this.scoreDescription}
					scoreColorClassNames={this.scoreColorClassNames}
					scoreColor={this.scoreColor}
					user={this.context.user}
					isNewUser={this.isNewUser}
					initiateDataGather={this.initiateDataGather}
				/>
				<Footer />
			</div>
		);
	}
}

App.contextType = UserContext;

export default App;
