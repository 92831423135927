import React, { Component } from 'react';
import '../Styles/PracticeSnapshots.scss';
import PracticeSnapshot from './PracticeSnapshot';


class PracticeSnapshots extends Component {
	state = {
		practiceGroupsData: this.props.data.practiceGroups
	}

	orderedPracticesByScore() {
		var sortedData = this.state.practiceGroupsData.slice();
		sortedData.sort(function(a, b) {
			var scoreA = a.scores[a.scores.length -1];
			var scoreB = b.scores[b.scores.length -1];
			if (scoreA === 0) { scoreA = 10; }
			if (scoreB === 0) { scoreB = 10; }
			return scoreA - scoreB;
		});
		return sortedData;
	}

	render() {
		return (
			<div className="practice-snapshots">
				<table>
					<tbody>
						{this.orderedPracticesByScore().map((practiceGroup, i) => {
							return (
								<tr key={i} id={"practice-group"+practiceGroup.id}>
									<td>
										<PracticeSnapshot 
											practiceGroup={practiceGroup}
											scoreDescription={this.props.scoreDescription}
											scoreColorClassNames={this.props.scoreColorClassNames}
										/>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		);
	}
}

export default PracticeSnapshots;