import React, {PureComponent} from 'react';
import classNames from 'classnames';
import '../Styles/HeatmapTeam.scss';
import HeatmapCell from './HeatmapCell';

class HeatmapTeam extends PureComponent {
	state = {
		t: null
	}

	handleHover = (event) => {
		if (!this.props.searchParams && this.props.searchParams !== '') {
			this.props.setCurrentTeam(this.props.team.name);
		}
	}

	handleHoverOut = () => {
		if (!this.props.searchParams && this.props.searchParams !== '') {
			clearTimeout(this.state.t);
			this.props.setCurrentTeam(null);
		}
	}

	componentWillUnmount() {
		clearTimeout(this.state.t);
	}

	onClick = () => {
		this.props.setModalTeam(this.props.team.name);
	}

	render() {
		return (
			<div
				className="heatmap-team"
				onMouseEnter={this.handleHover}
				onMouseLeave={this.handleHoverOut}
				onClick={this.onClick}>
				<div className={classNames("heatmap-team__inner",
					{"heatmap-team__not-selected": this.props.isNotSelected},
					{"heatmap-team__selected": this.props.isSelected}
					)}
					>
					<p>{this.props.team.name}</p>
					<div className="heatmap-team__cells-container">
						{this.props.team.practiceGroups.map((practiceGroup)=>{
							return (
								<div key={practiceGroup.name}>
									<HeatmapCell
										scoreColorClassNames={this.props.scoreColorClassNames}
										teamName={this.props.team.name}
										practiceGroup={practiceGroup}
										setCurrentData={this.setCurrentData}
										currentTeam={this.props.currentTeam}
										scoreDatesData={this.props.scoreDatesData} 
										dateSliderValue={this.props.dateSliderValue}
									/>
								</div>)
						})}
					</div>
				</div>
			</div>);
	}
}

export default HeatmapTeam;
