import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../Styles/InfoPanelPracticeCell.scss';
import trendarrow from '../Assets/Img/trendarrow.svg';
import ScoreBlock from './ScoreBlock';

class InfoPanelPracticeCell extends Component {
	constructor(props) {
		super(props);

		this.state.score = this.props.practice.score;
	}

	state = {
		scores: null,
		currentScore: null,
	};

	calculateTrend(currentScore, previousScore) {
		let trend = 'Unchanged';
		if (currentScore > previousScore && previousScore !== null) {
			trend = 'Improved';
		} else if (currentScore < previousScore) {
			trend = 'Regressed';
		}
		return trend;
	}

	render() {
		return (
			<div className="info-panel-practice-cell">
				<Link
					className="info-panel__view-button"
					to={{
						pathname: '/',
						state: {
							// data: this.props.data,
							// teamName: this.props.teamName,
							// practiceGroupName: this.props.practiceGroup.name,
							// practiceGroupId: this.props.practiceGroupId,
							// user: this.props.user,
							// setUser: this.props.setUser,
						},
					}}
				>
					<ScoreBlock
						score={this.state.score}
						scoreColorClassNames={this.props.scoreColorClassNames}
						trendarrow={trendarrow}
						overall= {false}
					/>
					<div className="info-panel-practice-cell__description">
						<span>{this.props.practice.question}</span>

						{/* <p className="info-panel-practice-cell__description__trend">
							{this.calculateTrend(this.state.currentScore, previousScore)}
						</p> */}
					</div>
				</Link>
			</div>
		);
	}
}

export default InfoPanelPracticeCell;
