import React, { Component } from 'react';
import '../Styles/TimeSeries.scss';
import Highcharts from 'highcharts';
// Import line chart.
// import IEcharts from 'react-echarts-v3/src/lite.js';
// import 'echarts/lib/chart/bar';

class Chart extends Component {
	constructor(props) {
		super(props);
		this.chartContainer = React.createRef();
	}

	componentDidMount() {
		//Cannot directly access this.props for options, need to create local var
		this.chart = new Highcharts[this.props.type || 'Chart'](
		this.chartContainer.current,
		// this.props.options
		this.setupChart());
	}

	formatDate(date) {
		
	}

	setupChart() {
		let scoreDescription = this.props.scoreDescription;
		//data[1] is insight data array
		let insightData = this.props.options.data[1];
		return {
			title: {
				text: ''
			},
			scrollablePlotArea: {
				minWidth: 700
			},
			xAxis: {
				categories: this.props.options.categories,
			},
			yAxis: {
				title: {
					text: '',
				},
				labels: {
					enabled: false
				}
			},
			legend: {
				enabled: false
			},
			chart: {
				type: 'area',
				height: 500,
				spacingBottom: 100,
				spacingTop: 50,
				position: {
					align: 'centre'
				},
				backgroundColor: '#F0F0F0',
			},
			series: [{
				//data[0] is array of scores
				animation: {
					duration: 2000
				},
				data: this.props.options.data[0]
			}],
			
			tooltip: {
				formatter: function() {
					let insightText = insightData[this.x - 1] ? insightData[this.x - 1] : "";
					return "" + insightText + "<br/>" +
					"<b>" + (this.y % 1 === 0 ? this.y : this.y.toFixed(2)) + " " + scoreDescription(this.y) + "</b>";
				}
			},
			plotOptions: {
				area: {
					fillOpacity: 0.5
				}
			}
		};
	}

	componentWillUnmount() {
		this.chart.destroy();
	}

	render() { 
		//Cannot directly access this.props for options, need to create local var
		if (this.chart != null) {
			this.chart.update(this.setupChart());
		}
		return <div ref={this.chartContainer} />;
	}
}

class TimeSeries extends Component {
	constructor(props) {
		super(props);
		this.state.teamDataForGraph = this.props.teamDataForGraph;
	}

	state = {
		dropdownValue: this.props.teamDataForGraph.practiceGroupName,
		teamDataForGraph: null
	}

	onChangeSelector = (event) => {
		this.setState({
			dropdownValue: event.target.value,
			teamDataForGraph: this.props.getDataForTimeSeries(this.props.data, this.props.teamData.name, event.target.value)
		});
	}

	render() {
		return (
			<div className = "TimeSeries">
				<div>
					<h2>EXPLORE <b>{this.state.teamDataForGraph.teamName}</b> TRENDS FOR</h2>
					<select 
						className="practice-dropdown"
						onChange={this.onChangeSelector}
						value={this.state.dropdownValue}>
							{this.props.teamData.practiceGroups.map((practiceGroup, i) => <option key={i}>{practiceGroup.name}</option>)}
					</select>
				</div>
				<Chart 
					options={this.state.teamDataForGraph} 
					scoreDescription={this.props.scoreDescription}/>
			</div>   
		);
	}
}

export default TimeSeries;