import React, { Component } from 'react';
import '../Styles/ThreeFiveThree.scss';
import moment from 'moment';
import ThreeFiveThreeCell from './ThreeFiveThreeCell';
import StepSlider from './StepSlider';
import InfoPanel from './InfoPanel';
import InfoPanelPracticeCell from './InfoPanelPracticeCell';
import ThreeFiveThreeLegend from './ThreeFiveThreeLegend';

class ThreeFiveThree extends Component {
	// TODO: replace hardcoded IDs with an API call
	state = {
		modalPractices: null,
		modalTeam: null,
		modalPracticeGroupId: null,
		modalPracticeGroupName: null,
	};

	practiceGroups = {
		rolesGroups: [13, 14, 15],
		eventsGroups: [16, 17, 18, 19, 20],
		artefactsGroups: [21, 22, 23],
	};

	practiceScoreForTeam(practiceId, team) {
		if (!this.props.scoreDatesData) {
			return { score: 0 };
		}
		const date = this.props.scoreDatesData[this.props.dateSliderValue];
		const scores = team.practices[practiceId + 1] ? team.practices[practiceId + 1].scores : null;
		let score = { score: 0 };
		if (scores) {
			scores.forEach(_score => {
				if (_score.score_date === date) {
					score = _score;
				}
			});
		}
		return score;
	}

	// Returns the average score for all practices in given practice group most recent (minus stepsBack) from a given date
	practiceGroupScoreForTeam = (practiceGroupId, team, stepsBack = 0) => {
		const { scoreDatesData, dateSliderValue } = this.props;
		const date = scoreDatesData[dateSliderValue];
		const practicesInGroup = this.filterPracticesInTeamByGroup(team, practiceGroupId);
		const filteredPractices = this.filterScoresInPracticesByDate(practicesInGroup, date, stepsBack);

		// Extract scores from filtered practices
		const scores = filteredPractices.map(practice => (practice.scores[0] ? practice.scores[0].score : null));

		// If scores exist return average
		if (scores.length > 0) {
			// Doesn't count the zeroes in its calculation
			let adjustedLength = 0;
			let sum = 0; 
			for (let i = 0; i < scores.length; i++) {
				if (scores[i] !== 0) {
					sum += scores[i];
					adjustedLength += 1;
				}
			}

			// If adjustedLength is zero, that means no scores are applicable to the practice group score
			if (adjustedLength === 0) {
				return adjustedLength;
			}
			const avg = sum / adjustedLength;			
			return avg;
		}
		// If no scores exist return 0
		return 0;
	};

	// Returns an array of the practice questions and scores that contribute to the average score in a practice group for a team
	getContributingScores = (practiceGroupId, team) => {
		const { scoreDatesData, dateSliderValue, practices } = this.props;
		const date = scoreDatesData[dateSliderValue];
		const practicesInGroup = this.filterPracticesInTeamByGroup(team, practiceGroupId);
		const filteredPractices = this.filterScoresInPracticesByDate(practicesInGroup, date);

		return filteredPractices.map(practice => ({
			question: practices.find(p => p.id.toString() === practice.practice_id.toString()).question,
			score: practice.scores[0] ? practice.scores[0].score : null,
		}));
	};

	// Returns the practices that fall within a given group and that the team has answered
	filterPracticesInTeamByGroup = (team, practiceGroupId) => {
		const teams = team.practices.filter(
			practice => practice.practice_group_id.toString() === practiceGroupId.toString()
		);
		return teams;
	};

	// Filter the scores within each practice by given date (returning the most recent from the given date)
	// "stepsBack" allows for returning scores previous to the most recent otherwise returned
	filterScoresInPracticesByDate = (practices, date, stepsBack = 0) =>
		practices.map(practice => {
			// Reverse scores so that finding a previous date if current doens't exist returns the most recent before given date
			const originalScores = [...practice.scores].reverse();
			let scores;
			scores = originalScores.filter(score => moment(score.score_date).isSameOrBefore(date));
			if (scores[stepsBack]) {
				scores = [scores[stepsBack]];
				const obj = { ...practice, scores };
				return obj;
			}
			// Else if there is no score at the given "stepsBack" return no scores
			scores = [];
			return { ...practice, scores };
		});

	getQuestionFromPracticeId = id => this.props.practices.find(practice => practice.id === id).question;

	handleSliderChange = value => {
		this.props.handleDateSlider(value);
	};

	getPracticeFromId = (practiceId, teamId) => {
		const teamPractices = this.props.data[teamId].practices;
		let found = null;
		teamPractices.forEach(function(practice) {
			if (practiceId === practice.practice_id) {
				found = practice;
			}
		});
		return found;
	};

	getPracticeGroupName = practiceGroupId => {
		const { practices } = this.props;
		let practice;
		if (practices) {
			practice = practices.find(p => p.practice_group_id.toString() === practiceGroupId.toString());
			return practice.practice_group_name;
		}
	};

	openModal = e => {
		const { teams } = this.props;
		const teamId = e.target.attributes.teamid.value;
		const practiceGroupId = e.target.attributes.practicegroupid.value;
		const practiceGroupName = this.getPracticeGroupName(practiceGroupId);
		const team = teams.find(t => t.id.toString() === teamId.toString());
		this.setState({
			modalPractices: this.getContributingScores(practiceGroupId, team),
			modalTeam: team,
			modalPracticeGroupId: practiceGroupId,
			modalPracticeGroupName: practiceGroupName,
		});
	};

	handleOnMouseOut = () => {
		this.setState({
			modalTeam: null,
		});
	};


	render() {
		const scoreDatesData = this.props.scoreDatesData ? this.props.scoreDatesData : [''];
		const { teams, practices } = this.props;
		const { modalTeam, modalPracticeGroupId } = this.state;
		return (
			<div className="three-five-three">

				<div className="three-five-three__dateSlider">
					<StepSlider
						value={this.props.dateSliderValue}
						min={0}
						max={scoreDatesData ? scoreDatesData.length - 1 : 0}
						step={1}
						handleOnChange={this.handleSliderChange}
						scoreColor="#FFF"
					/>
				</div>
				<div className="three-five-three__date">
					{scoreDatesData[this.props.dateSliderValue] ? scoreDatesData[this.props.dateSliderValue].split(' ')[0] : ''}
				</div>
				<div className="three-five-three-with-legend">
					<div className="three-five-three__view">
						{this.props.data && teams && modalTeam && (
							<InfoPanel
								scoreColorClassNames={this.props.scoreColorClassNames}
								teamData={modalTeam}
								handleOnMouseOut={this.handleOnMouseOut}
								setCurrentTeam={this.setCurrentTeam}
								practiceGroupName={this.state.modalPracticeGroupName}
								averageScore={this.practiceGroupScoreForTeam(modalPracticeGroupId, modalTeam)}
								previousAverageScore={this.practiceGroupScoreForTeam(modalPracticeGroupId, modalTeam, 1)}
							>
								<div>
									{this.state.modalPractices.map((practice, i) => (
										<div key={i}>
											<InfoPanelPracticeCell
												practice={practice}
												data={this.props.data}
												scoreDatesData={this.props.scoreDatesData}
												dateSliderValue={this.props.dateSliderValue}
												scoreColorClassNames={this.props.scoreColorClassNames}
											/>
										</div>
									))}
								</div>
							</InfoPanel>
						)}

						{this.props.data &&
							teams &&
							practices &&
							teams.map((team, i) => (
								<div className="tft-row" key={team.name}>
									<div className="tft-row__team-name">{team.name}</div>
									{this.practiceGroups.rolesGroups && (
										<div className="tft-row__cells">
											{this.practiceGroups.rolesGroups.map(practiceGroupId => {
												// Get the practice group heading for this column

												const practiceGroup = practices.find(
													practice => practice.practice_group_id.toString() === practiceGroupId.toString()
												);

												return (
													<div key={practiceGroupId}>
														{i === 0 && (
															<div className="tft-row__cells_practice">
																{practiceGroup && practiceGroup.practice_group_name}
															</div>
														)}

														<ThreeFiveThreeCell
															practiceGroupId={practiceGroupId}
															teamId={team.id}
															onClick={this.openModal}
															scoreColorClassNames={this.props.scoreColorClassNames}
															score={this.practiceGroupScoreForTeam(practiceGroupId, team)}
															scoreDatesData={this.props.scoreDatesData}
															dateSliderValue={this.props.dateSliderValue}
															previousScore={this.practiceGroupScoreForTeam(practiceGroupId, team, 1)}
														/>
													</div>
												);
											})}
										</div>
									)}
									{this.practiceGroups.eventsGroups && (
										<div className="tft-row__cells">
											{this.practiceGroups.eventsGroups.map(practiceGroupId => {
												const practiceGroup = practices.find(
													practice => practice.practice_group_id.toString() === practiceGroupId.toString()
												);
												return (
													<div key={practiceGroupId}>
														{i === 0 && (
															<div className="tft-row__cells_practice">
																{practiceGroup && practiceGroup.practice_group_name}
															</div>
														)}

														<ThreeFiveThreeCell
															practiceGroupId={practiceGroupId}
															teamId={team.id}
															onClick={this.openModal}
															scoreColorClassNames={this.props.scoreColorClassNames}
															score={this.practiceGroupScoreForTeam(practiceGroupId, team)}
															scoreDatesData={this.props.scoreDatesData}
															dateSliderValue={this.props.dateSliderValue}
															previousScore={this.practiceGroupScoreForTeam(practiceGroupId, team, 1)}
														/>
													</div>
												);
											})}
										</div>
									)}
									{this.practiceGroups.artefactsGroups && (
										<div className="tft-row__cells">
											{this.practiceGroups.artefactsGroups.map(practiceGroupId => {
												const practiceGroup = practices.find(
													practice => practice.practice_group_id.toString() === practiceGroupId.toString()
												);
												return (
													<div key={practiceGroupId}>
														{i === 0 && (
															<div className="tft-row__cells_practice">
																{practiceGroup && practiceGroup.practice_group_name}
															</div>
														)}

														<ThreeFiveThreeCell
															practiceGroupId={practiceGroupId}
															teamId={team.id}
															onClick={this.openModal}
															scoreColorClassNames={this.props.scoreColorClassNames}
															score={this.practiceGroupScoreForTeam(practiceGroupId, team)}
															scoreDatesData={this.props.scoreDatesData}
															dateSliderValue={this.props.dateSliderValue}
															previousScore={this.practiceGroupScoreForTeam(practiceGroupId, team, 1)}
														/>
													</div>
												);
											})}
										</div>
									)}
								</div>
							))}
							
					</div>
					<div className="three-five-three-legend">
						<ThreeFiveThreeLegend scoreColorClassNames={this.props.scoreColorClassNames}/>
					</div>
				</div>


			</div>
		);
	}
}

export default ThreeFiveThree;
