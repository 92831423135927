import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

const styles = theme => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	margin: {
		marginBottom: 20,
		marginTop: 20,
	},
	withoutLabel: {
		marginTop: theme.spacing.unit * 3,
	},
	textField: {
		flexBasis: 200,
	},
	resize: {
		fontSize: 16,
	},
});

class InputAdornments extends React.Component {
	state = {
		picker_value: this.props.defaultValue || '-1',
	};

	componentDidUpdate(prevProps) {
		if (prevProps.defaultValue !== this.props.defaultValue) {
			this.setState({
				picker_value: this.props.defaultValue,
			});
		}
	}

	handleChange = prop => event => {
		this.setState({ [prop]: event.target.value });
		this.props.updateFormData(this.props.name, event.target.value);
	};

	handleClickShowPassword = () => {
		this.setState(state => ({ showPassword: !state.showPassword }));
	};

	render() {
		const { classes, variant, range } = this.props;
		const { picker_value } = this.state;
		return (
			<div className={classes.root}>
				<TextField
					select
					InputProps={{
						classes: {
							input: classes.resize,
						},
					}}
					variant={variant}
					className={classNames(classes.margin, classes.textField)}
					value={picker_value}
					onChange={this.handleChange('picker_value')}
				>
					{range.map(option => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</TextField>
			</div>
		);
	}
}

InputAdornments.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InputAdornments);
