import React, { Component } from 'react';
import '../Styles/PracticeSnapshot.scss';

class PracticeSnapshot extends Component {
	state = {
		score: this.props.practiceGroup.scores[this.props.practiceGroup.scores.length - 1],
		insight: this.props.practiceGroup.scores[this.props.practiceGroup.scores.length - 1].insight
	}

	getPracticeGroupLink() {
		switch (this.props.practiceGroup.name) {
			case "Scrum of Scrums": { return "https://www.scruminc.com/scrum-of-scrums/"; }
			case "WOW Program": { return "https://martinfowler.com/articles/agileFluency.html"; }
			case "Team Kickoff": { return "https://luis-goncalves.com/team-kick-off-meeting/"; }
			case "Continuous Improvement": { return "https://www.mountaingoatsoftware.com/reviews/agile-retrospectives"; }
			case "Tools": { return "https://thedigitalprojectmanager.com/best-scrum-tools/"; }
			case "Roles": { return "https://www.scrumalliance.org/agile-resources/scrum-roles-demystified"; }
			case "Backlog": { return "https://www.atlassian.com/agile/scrum/backlogs"; }
			case "Norms": { return "https://www.atlassian.com/wac/team-playbook/plays/rules-of-engagement"; }
			case "Output Metrics": { return "https://www.scruminc.com/velocity/"; }
			case "Product": { return "https://svpg.com/defining-product/"; }
			case "Customers and Market": { return "https://a16z.com/2017/06/09/distribution-model-sales-channels/"; }
			case "Team Cohesion": { return "https://medium.com/taskworld-blog/lencionis-5-dysfunctions-of-a-team-330d58b2cd81"; }
			default: return "";
		}
	}

	getPracticeGroupDescription() {
		switch (this.props.practiceGroup.name) {
			case "Scrum of Scrums": { return "The Scrum of Scrums is responsible to help teams with blockers and dependencies they can’t resolve themselves."; }
			case "WOW Program": { return "Having an endorsed Ways of Working (WOW) strategy in place allows teams to focus on the right practices at the right time."; }
			case "Team Kickoff": { return "Has the team aligned on their mission-purpose, and created a team identity?"; }
			case "Continuous Improvement": { return "Are people at all levels actively engaged in suggesting and implementing improvements to the company?"; }
			case "Tools": { return "Are the right tools in place to enable visibility, inspection and adaptation of work and outcomes?"; }
			case "Roles": { return "Are the right roles in place to enable visibility, inspection and adaptation of work and outcomes?"; }
			case "Backlog": { return "Is there a prioritised, ordered list of work by value that is regularly collaborated on?"; }
			case "Norms": { return "Teams that take the time to agree on their norms will waste less effort in trying to constantly keep up with each others expectations."; }
			case "Output Metrics": { return "Do the team have empirical evidence of how much work they can get done over a time period? Could this be used to forecast outwards?"; }
			case "Product": { return "Do the team have a focus on understanding real value and working towards customer outcomes, rather than focussing on the output needed to get there?"; }
			case "Customers and Market": { return "Do the team have an understanding of their current and potential market, and see the link between their work and how to best solve customer problems strategically?"; }
			case "Team Cohesion": { return "Does the team give consistent answers on their members, purpose, customers, strategy, norms?"; }
			default: return "";
		}
	}

	render() {
		let insights = this.props.practiceGroup.insights;
		let insight = insights[insights.length - 1]?insights[insights.length - 1]:"No Comment";
		return (
			<div className="practice-snapshot">
				<div className={this.props.scoreColorClassNames("practice-snapshot__score", this.state.score.score)}>
					<p>{this.state.score.score % 1 === 0 ? this.state.score.score : this.state.score.score.toFixed(2)}</p>
					<br></br>
					<p>{this.props.scoreDescription(this.state.score.score)}</p>
				</div>
				<div className="practice-snapshot__description">
					<div className="description-text">
						<p><b>{this.props.practiceGroup.name}</b></p>
					</div>
					<div className="description-text">
						<p>{this.getPracticeGroupDescription()}</p>
					</div>
				</div>
				<div className="practice-snapshot__insights">
					<p>Comment:</p>
					<br/>
					<p>{insight}</p>
					<br/>
				</div>
				<a href={this.getPracticeGroupLink()} target=":blank">
					<div className="practice-snapshot__action-button">
						Learn More
					</div>
				</a>
			</div>
		);
	}
}

export default PracticeSnapshot;