import decode from 'jwt-decode';
import axios from 'axios';
import { apiHost } from '../config';

const setToken = token => {
	localStorage.setItem('id_token', token);
};
// Retrieves the user token from localStorage
const getToken = () => localStorage.getItem('id_token');

// Intercepts axios requests and attaches the JWT bearer token
export const authAxios = axios.create();
authAxios.interceptors.request.use(config => {
	const token = getToken();
	config.headers.Authorization = `Bearer ${token}`;
	return config;
});

// const isTokenExpired = token => {
// 	try {
// 		const decoded = decode(token);
// 		if (decoded.exp < Date.now() / 1000) {
// 			// Checking if token is expired
// 			return true;
// 		}
// 		return false;
// 	} catch (err) {
// 		return false;
// 	}
// };

// Using jwt-decode npm package to decode the token
export const getProfile = () => decode(getToken());

export const login = ({ email_address, password }) => {
	const api = `${apiHost}/api/auth/login`;
	const cred = {
		email_address,
		password,
	};
	return authAxios.post(api, cred).then(res => {
		const { access_token } = res.data;
		if (res.status === 200) {
			setToken(access_token); // Setting the token in localStorage
			return Promise.resolve(res);
		}
		return Promise.reject(res);
	});
};
export const loggedIn = () => {
	// Checks if there is a saved token and it's still valid
	const token = getToken(); // Getting token from localstorage
	// return !!token && !isTokenExpired(token); // handwaiving here Temporarily disable check for token expiry until refresh tokens are implemented on the backend
	return !!token; // handwaiving here
};

export const logout = () => {
	// Clear user token and profile data from localStorage
	localStorage.removeItem('id_token');
};
