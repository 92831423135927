import React, { Component } from 'react';
import { object, number, func } from 'prop-types';
import '../../Styles/DataEntryYesNo.scss';
import '../../Styles/SelectionYesNo.scss';
import SelectionYesNo from '../SelectionYesNo';

class DataEntryYesNo extends Component {
	state = { value: 0 };

	constructor(props) {
		super(props);
		const { prefillValue } = this.props;
		this.state.value = prefillValue;
	}

	componentDidUpdate(prevProps, prevState) {
		const { prefillValue } = this.props;
		if (prevProps.prefillValue !== prefillValue) {
			this.setState({
				value: prefillValue,
			});
		}
	}

	handleOnChange = value => {
		const { readScores, practice } = this.props;
		this.setState({ value });
		readScores(practice.id, practice.question, value);
	};

	positiveValue = 4;

	negativeValue = 1;

	neutralValue = 0;

	selfBlockValue = -1;

	render() {
		const { practice, scoreColor } = this.props;
		const { value } = this.state;

		return (
			<div className="data-entry-yes-no">
				<div className="data-question">
					<p>{practice.question}</p>
					<div className="flex-push" />
					{/*	Is there a way to call a function from SelectionYesNo to return some HTML so that I don't
					    have to manage the <input><label> template from two locations? */}
					<div className="button-group self-blocking">
						{/* onChange must be specified due to the way controlled components work, however
							this has been handled in the label, because "input" is actually a hidden radio button
							and the actual setting actually lies in the label */}
						<input
							type="radio"
							id={`radio-block-${practice.id}`}
							className="self-blocking"
							name={`group-${practice.id}`}
							checked={value === this.selfBlockValue}
							onChange={() => this.handleOnChange(this.selfBlockValue)}
						/>
						<label
							htmlFor={`radio-block-${practice.id}`}
							className="self-blocking"
							onClick={() => this.handleOnChange(this.selfBlockValue)}
						>
							Self-blocking
						</label>
					</div>
				</div>

				<div className="data-entry-component-yes-no">
					<SelectionYesNo
						value={value}
						handleOnChange={this.handleOnChange}
						positiveValue={this.positiveValue}
						negativeValue={this.negativeValue}
						neutralValue={this.neutralValue}
						questionId={practice.id}
						scoreColor={scoreColor(value)}
					/>
				</div>

				{/*
				<p className="yes-no-description">
					{value} - {scoreDescription(value)}
				</p>
				*/}
			</div>
		);
	}
}

DataEntryYesNo.propTypes = {
	practice: object,
	prefillValue: number,
	readScores: func,
	scoreColor: func,
	scoreDescription: func,
};

export default DataEntryYesNo;
