import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../../Styles/DataEntrySlider.scss';
import StepSlider from '../StepSlider';

class DataEntrySlider extends Component {
	state = {
		value: 0,
	};

	constructor(props) {
		super(props);
		const { prefillValue } = this.props;
		// for (var key in this.props.practiceScores) {
		// 	let practiceScore = this.props.practiceScores[key];
		// 	if (this.props.practice.practice_id === practiceScore.practice_id) {
		// 		this.state = {
		// 			value: practiceScore.score
		// 		};
		// 	}
		// }
		this.state.value = prefillValue;
	}

	componentDidUpdate(prevProps, prevState) {
		const { prefillValue } = this.props;
		if (prevProps.prefillValue !== prefillValue) {
			this.setState({
				value: prefillValue,
			});
		}
	}

	handleOnChange = value => {
		const { readScores, practice } = this.props;
		this.setState({
			value,
		});
		readScores(practice.id, practice.question, value);
	};

	render() {
		const { practice, scoreColor, scoreDescription } = this.props;
		const { value } = this.state;
		return (
			<div className="data-entry-slider">
				<div className="data-question">
					<p>{practice.question}</p>
				</div>

				<StepSlider
					value={value}
					min={-1}
					max={4}
					step={1}
					handleOnChange={this.handleOnChange}
					scoreColor={scoreColor(value)}
				/>

				<p className="slider-description">
					{value} - {scoreDescription(value)}
				</p>
			</div>
		);
	}
}

DataEntrySlider.propTypes = {
	prefillValue: PropTypes.number,
	readScores: PropTypes.func,
	practice: PropTypes.object,
	scoreColor: PropTypes.func,
	scoreDescription: PropTypes.func,
};

export default DataEntrySlider;
