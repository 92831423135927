import React from 'react';
import '../Styles/ScoreBlock.scss';

const ScoreBlock = ({ score, scoreColorClassNames, trendarrow, overall, trend }) => {
	// Checks if the score is a decimal and changes it to 2 d.p
	if (score % 1 !== 0) {
		score = score.toFixed(2);
	}

	// Checks if score is 0 to render '-'.
	let s = score;
	if (score === 0) {
		s = '-' 
	}

	//If the scoreBlock pertains to the overall average, do not show trend arrow. Otherwise, decide on what arrow to show.
	let arrowName;
	if (overall) {
		arrowName = 'trend-arrow__none';
	} else {	
		switch (trend) {
			case "Unchanged":
				arrowName = "trend-arrow__none";
				break;
			case "Regressed":
				arrowName = "trend-arrow__down";
				break;
			case "Improved":
				arrowName = "trend-arrow__up"
				break;
			default:
				arrowName = 'trend-arrow__up';
		}
	}

	return (
		<div className={scoreColorClassNames('score', score)}>
			<p>{overall ? score : s}</p>
			<img className={arrowName} src={trendarrow} alt="trend arrow" />
		</div>
	)
	
};
export default ScoreBlock;
