import React, { Component } from 'react';
import '../Styles/TeamForm.scss';
import { Redirect } from 'react-router-dom';
import { authAxios } from './Auth';
import { apiHost } from '../config';
import Navbar from './Navbar';
import NumberEntryField from './FormComponents/NumberEntryField';
import Button from './FormComponents/Button';
import SingleLineTextField from './FormComponents/SingleLineTextField';
import DatePicker from './FormComponents/DatePicker';
import Picker from './FormComponents/Picker';

const isDistributedRange = [
	{
		value: 1,
		label: 'Yes',
	},
	{
		value: 0,
		label: 'No',
	},
];

class TeamForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoaded: false,
			isSubmitted: false,
			practiceScores: {},
			visibleList: {
				// Update to first array in practice group
				'Scrum of Scrums': true,
			},
			form: {},
			teamId: null,
			organisationsRange: [],
		};
	}

	componentDidMount() {
		this.populateOrganisations();
	}

	populateOrganisations() {
		if (!this.props.user) {
			return;
		}
		const api = `${apiHost}/api/users/${this.props.user.id}/organisations`;
		authAxios
			.get(api)
			.then(res => {
				if (res.status === 200) {
					console.log(res.data.organisations);
					const _organisationsRange = this.state.organisationsRange;
					res.data.organisations.forEach(organisation => {
						_organisationsRange.push({
							value: organisation.id,
							label: organisation.name,
						});
					});
					this.setState({
						organisationsRange: _organisationsRange,
					});
				}
			})
			.catch(err => {
				console.log(err);
			});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!prevProps.data && this.props.data) {
			this.setupFormDataObject();
		}
	}

	setupFormDataObject() {
		const _practiceScores = {};
		// loop through a set of all practices
		this.props.data[0].practices.forEach(practice => {
			// extract practice group id
			const practiceScore = {};
			practiceScore.team_id = 1;
			practiceScore.practice_id = practice.practice_id;
			practiceScore.practice = practice.display_name;
			practiceScore.score = 0;
			// practiceScore["score_date"] = score_date;
			_practiceScores[practice.practice_id] = practiceScore;
		});
		this.setState({
			practiceScore: _practiceScores,
		});
	}

	toggleVisible = current => {
		const _visibleList = this.state.visibleList;
		_visibleList[current.practice_group_name] = !_visibleList[current.practice_group_name];
		this.setState({
			visibleList: _visibleList,
		});
	};

	submitForm = e => {
		e.preventDefault();
		if (!this.state.form.name) {
			this.setState({
				errors: ['Missing Team Name'],
			});
			return;
		}
		const api = `${apiHost}/api/teams`;
		authAxios
			.post(api, this.state.form)
			.then(res => {
				if (res.status === 201) {
					this.createUserTeamEntry(res.data.last_id[0]);
					this.setState({
						isSubmitted: true,
						teamId: res.data.last_id[0],
					});
				}
			})
			.catch(err => {
				console.log(err);
			});
	};

	createUserTeamEntry(newTeamId) {
		const { id } = this.props.user;
		const api = `${apiHost}/api/users${id}`;
		const user_team = {
			team_id: newTeamId,
		};
		authAxios
			.put(api, user_team)
			.then(res => {
				if (res.status === 201) {
					console.log('new team 👍');
				}
			})
			.catch(err => {
				console.log(err);
			});
	}

	healthCheckFormData() {
		// create an empty array
		const practiceGroups = [];
		if (this.props.data != null) {
			// loop through a set of all practices
			this.props.data[0].practices.forEach(practice => {
				// extract practice group id
				const practiceGroupId = practice.practice_group_id;
				practice.visible = true;
				if (!practiceGroups[practiceGroupId]) {
					// if practice group id key does not exist in array, add it as empty array
					practiceGroups[practiceGroupId] = [];
				}
				// push practice into practice groups array with associated practiceGroupId
				practiceGroups[practiceGroupId].push(practice);
			});
		}
		return practiceGroups;
	}

	updateFormData = (name, value) => {
		const _form = this.state.form;
		_form[name] = value;
		this.setState({
			form: _form,
		});

		if (name === 'organisationId') {
			console.log('Stuff');
			// this.setTeamPracticesWithId(value);
		}
	};

	render() {
		// TODO: extract to app.js function
		if (!this.props.user) {
			return <Redirect to="/" />;
		}

		if (this.state.isSubmitted) {
			return (
				<Redirect
					to={{
						pathname: '/healthCheckForm',
						state: {
							teamId: this.state.teamId,
							prevPage: '/teamForm',
						},
					}}
				/>
			);
		}

		return (
			<div className="team-form">
				<Navbar pageName={this.props.pageName} isNewUser={this.props.isNewUser} />

				<div className="health-form-title">
					<h2>Create a New Team</h2>
				</div>

				<div className="team-question">What is the team name?</div>

				<SingleLineTextField name="name" updateFormData={this.updateFormData} />

				<div className="team-question">What organisation does this team belong to?</div>

				<Picker
					name="organisation_id"
					updateFormData={this.updateFormData}
					range={this.state.organisationsRange}
					variant="outlined"
				/>

				<div className="team-question">How many people are in the team?</div>

				<NumberEntryField name="member_count" updateFormData={this.updateFormData} />

				<div className="team-question">Who is the Product Owner?</div>

				<div className="instruction">Leave blank if none</div>

				<SingleLineTextField name="product_owner" updateFormData={this.updateFormData} />

				<div className="team-question">Who is the Scrum Master?</div>

				<div className="instruction">Leave blank if none</div>

				<SingleLineTextField name="scrum_master" updateFormData={this.updateFormData} />

				<div className="team-question">Is the team distributed?</div>

				<Picker
					name="is_distributed"
					updateFormData={this.updateFormData}
					range={isDistributedRange}
					variant="outlined"
				/>

				<div className="team-question">Which offices are the team based in?</div>

				<SingleLineTextField name="location" updateFormData={this.updateFormData} />

				<div className="team-question">What work or projects are they focused on at the moment?</div>

				<SingleLineTextField name="current_focus" updateFormData={this.updateFormData} />

				<div className="team-question">When did the team form?</div>

				<DatePicker className="DatePicker" name="created_date" updateFormData={this.updateFormData} />

				<div>
					<Button onClick={this.submitForm} />
				</div>

				{this.state.errors &&
					this.state.errors.map((error, i) => (
						<div key={i}>
							<li>{error}</li>
						</div>
					))}
			</div>
		);
	}
}

export default TeamForm;
