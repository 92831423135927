import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../Styles/HeatmapInfoPanelPracticeCell.scss';
import classNames from 'classnames';
import trendarrow from '../Assets/Img/trendarrow.svg';


class HeatmapInfoPanelPracticeCell extends Component {
	constructor(props) {
		super(props);
		let scores = this.props.practiceGroup.scores;
		this.state.scores = scores;
		this.state.currentScore = this.score(this.props.dateSliderValue).score;
	}

		//looks for score with date of at the current date index in group practice
	score(index) {
		let score = { score: 0 };
		if (!this.props.scoreDatesData) { return score; }
		let date = this.props.scoreDatesData[index];
		if (!date) { return null }
		let scores = this.props.practiceGroup.scores ? this.props.practiceGroup.scores : null;
		if (scores) {
			scores.forEach((_score) => {
				if(_score.date === date) {
					score = _score;
				}
			});
		}
		return score;
	}

	state = {
		scores: null,
		currentScore: null,
	}

	calculateTrend(currentScore, previousScore) {
		let trend = "-";
		if (currentScore > previousScore && previousScore !== null) {
			trend = "Improved";
		} else if (currentScore < previousScore) {
			trend = "Regressed";
		}
		return trend;
	}

	render() {
		let previousScore = this.score(this.props.dateSliderValue - 3) ? this.score(this.props.dateSliderValue - 3).score : null;

		return (
			<div className="heatmap-info-panel-practice-cell">
				<Link 
                    className="heatmap-info-panel__view-button"
                    to={{
                        pathname: "/teamView",
                        state: {
                            data: this.props.data,
                            teamName: this.props.teamName,
                            practiceGroupName: this.props.practiceGroup.name,
                            practiceGroupId: this.props.practiceGroupId,
                            user: this.props.user,
                            setUser: this.props.setUser
                        }
                    }}>
					<div className={this.props.scoreColorClassNames("score", this.state.currentScore)}>
						<p>{this.state.currentScore % 1 === 0 ? this.state.currentScore : this.state.currentScore.toFixed(2)}</p>
						<img
	    					className={classNames(
	    						"trend-arrow",
	    						{'trend-arrow__none': this.state.currentScore === previousScore || previousScore === null},
	    						{'trend-arrow__up': this.state.currentScore > previousScore && previousScore !== null},
	    						{'trend-arrow__down':  this.state.currentScore < previousScore && previousScore !== null},
	    					)}
	    					src={trendarrow}
	                        alt="trend arrow"
	    				/>
					</div>
					<div className="heatmap-info-panel-practice-cell__description">
						<p>{this.props.practiceGroup.name}</p>
						<p className="heatmap-info-panel-practice-cell__description__trend">
							{this.calculateTrend(this.state.currentScore, previousScore)}
						</p>
					</div>
                </Link>
			</div>
		);
	}
}

export default HeatmapInfoPanelPracticeCell;