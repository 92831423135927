import React from 'react';
import '../Styles/ThreeFiveThreeLegend.scss';

const ThreeFiveThreeLegend = props => {

    return (
        <div className='column'>
            <div className='colours-column'>
                <div className={props.scoreColorClassNames('three-five-three-legend', 0)}></div>
                <div className={props.scoreColorClassNames('three-five-three-legend', -1)}></div>
                <div className='spacer'></div>
                <div className={props.scoreColorClassNames('three-five-three-legend', 1)}></div>
                <div className={props.scoreColorClassNames('three-five-three-legend', 2)}></div>
                <div className={props.scoreColorClassNames('three-five-three-legend', 3)}></div>
                <div className={props.scoreColorClassNames('three-five-three-legend', 4) + ' effective-practice'}></div>
            </div>
            
            <div className='description-column'>
                <div className='legend-description'>Not measured/applicable</div>
                <div className='legend-description'>Self-blocking</div>
                <div className='spacer'></div>
                <div className='legend-description'>Practice not evident</div>
                <div className='legend-description'>Inconsistent practice</div>
                <div className='legend-description'>Consistent practice</div>
                <div className='legend-description '>Effective practice</div>
            </div>
        </div>
    )
};

export default ThreeFiveThreeLegend;