import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		width: 200,
		marginBottom: 20,
		marginTop: 20,
	},
	resize:{
		fontSize:16
	},
});

class DatePicker extends React.PureComponent {

	handleChange = prop => event => {
		this.setState({ [prop]: event.target.value });
		this.props.updateFormData(this.props.name, event.target.value);
	};

	render() {
		const { classes } = this.props;
		return (
			<div className={classes.root}>
				<form className={classes.container} noValidate>
					<TextField
						id="date"
						type="date"
						defaultValue={this.props.defaultDate}
						variant="outlined"
						className={classes.textField}
						InputLabelProps={{
							shrink: true,
						}}
						InputProps={{
							classes: {
								input: classes.resize,
							},
						}}
						onChange={this.handleChange('date')}
					/>
				</form>
			</div>
		);
	}
}

DatePicker.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DatePicker);
