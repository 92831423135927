import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../Styles/HeatmapInfoPanel.scss';
import classNames from 'classnames';
import HeatmapInfoPanelPracticeCell from './HeatmapInfoPanelPracticeCell';
import MdClose from 'react-icons/lib/md/close'

class HeatmapInfoPanel extends Component {

	handleOnMouseOut = (event) => {
		this.props.setModalTeam(null);
	}

	render() {
		let isHidden = this.props.modalTeam == null;
		let practiceGroups = [];
		if (!this.props.isEmpty(this.props.teamData)) {
			practiceGroups = this.props.teamData.practiceGroups
		}
		let teamDom = this.props.refs[this.props.teamData.name];
		let style = {};
		if (teamDom != null) {
			style = {
				top: window.scrollY + 170
			};
		}

		return (

			<div className={classNames(
					"heatmap-info-panel",
					{'is-hidden': isHidden }
				)} style={style}>

				<div>
					<div className="heatmap-info-panel-close">
						<MdClose classname="md-close" onClick={this.handleOnMouseOut}/>
					</div>
				</div>
					
				<div className="heatmap-info-panel_header">



					<div className="heatmap-info-panel-team">
						<p className="heatmap-info-panel__team-name-header">TEAM NAME</p>
						<h2 className="heatmap-info-panel__team-name">{this.props.teamData.name}</h2>
					</div>

					<div className="heatmap-info-panel-actions">

						<div className="heatmap-info-panel-button">
							<Link to={{
							pathname: "/healthCheckForm",
							state: {
								teamId: this.props.teamData.id,
								prevPage: "/teamForm"
							}
							}}
							>
							<div className="update_team">Update Team ></div>
							</Link>
						</div>
					</div>

				</div>

				<div className="heatmap-info-panel__box">
					<div>
						{practiceGroups.map((practiceGroup, i) => {
							return (
								<div key={practiceGroup.name+i}>
									<HeatmapInfoPanelPracticeCell
										practiceGroup={practiceGroup}
										data={this.props.data}
										scoreDatesData={this.props.scoreDatesData}
										dateSliderValue={this.props.dateSliderValue}
										teamName={this.props.teamData.name}
										practiceGroupId={i}
										scoreColorClassNames={this.props.scoreColorClassNames}
									/>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		);
	}
}

export default HeatmapInfoPanel;
