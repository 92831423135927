import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { authAxios } from './Auth';
import { apiHost } from '../config';
import '../Styles/TeamHealth.scss';
import Heatmap from './Heatmap';
import Navbar from './Navbar';
import Subscribe from './MailchimpSubscribe';
import HeatmapImg from '../Assets/Img/heatmap_img.png';
import ThreeFiveThree from './ThreeFiveThree';
import Picker from './FormComponents/Picker';

class TeamHealth extends Component {
	constructor(props) {
		const viewOpts = ['SCRUM 3-5-3', 'AGILITY'];
		super(props);
		this.state = {
			isLoaded: false,
			searchParam: '',
			viewOpts,
			selectedView: viewOpts[0],
			form: { organisation_id: -1 },
			organisationsRange: [],
		};
		if (this.props.data) {
			this.populateTeamsArray();
			if (this.props.user) {
				this.populateOrganisations();
				if (localStorage.getItem(`${this.props.user.id}-organisation-id`)) {
					this.state.form.organisation_id = localStorage.getItem(`${this.props.user.id}-organisation-id`);
				}
			}
		}
	}

	componentDidMount() {
		if (this.props.location.state) {
			if (this.props.location.state.prevPage === 'healthCheckForm' && !this.state.isLoaded && this.props.user) {
				this.props.initiateDataGather(this.props.user);
				this.props.history.replace({ state: {} });
				this.setState({
					isLoaded: true,
				});
			}
		}
		const teams = this.getTeamsFromOrganisation();
		this.setState({ teams });
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.user && this.props.user) {
			this.populateOrganisations();
			if (localStorage.getItem(`${this.props.user.id}-organisation-id`)) {
				this.setState({
					form: {
						organisation_id: localStorage.getItem(`${this.props.user.id}-organisation-id`),
					},
				});
			}
		}
		if (!prevProps.data && this.props.data) {
			this.populateTeamsArray();
			const teams = this.getTeamsFromOrganisation();
			this.setState({ teams });
		}

		if (prevProps.user && !this.props.user) {
			this.setState({ organisationsRange: [] });
		}
	}

	handleSearchInput = event => {
		const { target } = event;
		let { value } = target;
		if (value === '') {
			value = null;
		}
		this.setState({
			searchParams: value,
		});
	};

	chooseToggle = selection => {
		this.setState({
			selectedView: selection,
		});
	};

	populateTeamsArray() {
		const teamsRange = this.props.data.map(team => ({
			value: team.id,
			label: team.name,
		}));
		this.setState({ teamsRange });
	}

	populateOrganisations() {
		if (!this.props.user) {
			return;
		}
		const api = `${apiHost}/api/users/${this.props.user.id}/organisations`;
		authAxios
			.get(api)
			.then(res => {
				if (res.status === 200) {
					// console.log(res.data['records']);
					const _organisationsRange = this.state.organisationsRange;
					_organisationsRange.push({
						value: -1,
						label: 'Choose company...',
					});
					res.data.organisations.forEach(organisation => {
						_organisationsRange.push({
							value: organisation.id,
							label: organisation.name,
						});
						this.setState({
							organisationsRange: _organisationsRange,
						});
					});
				}
			})
			.catch(err => {
				console.log(err);
			});
	}

	updateFormData = (name, value) => {
		const _form = this.state.form;
		_form[name] = value;
		this.setState({
			form: _form,
		});
		localStorage.setItem(`${this.props.user.id}-organisation-id`, _form.organisation_id);
		// Get teams for selected organisation
		const teams = this.getTeamsFromOrganisation();
		this.setState({ teams });
	};

	getTeamsFromOrganisation = () => {
		const array = [];
		if (this.props.data) {
			this.props.data.forEach(team => {
				if (team.organisation_id === Number(this.state.form.organisation_id)) {
					array.push(team);
				}
			});
		}
		return array;
	};

	selectedView = () => {
		switch (this.state.selectedView) {
			case this.state.viewOpts[0]:
				return (
					<ThreeFiveThree
						scoreColorClassNames={this.props.scoreColorClassNames}
						data={this.props.data}
						scoreDatesData={this.props.scoreDatesData}
						dateSliderValue={this.props.dateSliderValue}
						handleDateSlider={this.props.handleDateSlider}
						teams={this.state.teams}
						practices={this.props.practices}
					/>
				);
			case this.state.viewOpts[1]:
				return (
					<Heatmap
						searchParams={this.state.searchParams}
						scoreDescription={this.props.scoreDescription}
						scoreColorClassNames={this.props.scoreColorClassNames}
						data={this.props.data}
						scoreDatesData={this.props.scoreDatesData}
						dateSliderValue={this.props.dateSliderValue}
						handleDateSlider={this.props.handleDateSlider}
						practices={this.props.practices}
						organisationId={this.state.form.organisation_id}
						teams={this.state.teams}
						getTeamsFromOrganisation={this.getTeamsFromOrganisation}
					/>
				);
			default:
				return <div></div>;
		}
	};

	render() {
		const { isNewUser, data } = this.props;
		let view;
		if (this.props.user === null) {
			view = (
				<div style={{ color: 'white' }}>
					<div className="container">
						<div>
							<h1 className="logged_out_copy">Unleash your teams</h1>
						</div>
						<div className="row">
							<div className="col">
								<img className="heatmap_img float-right" src={HeatmapImg} alt="Sample of heatmap" />
							</div>
							<div className="col about-team-insights">
								<div className="about-team-insights-copy">
									Understand where they need your support, learn how you can help
									<div className="mailchimp-subscribe">
										<Subscribe />
									</div>
								</div>
							</div>
						</div>
					</div>
					<h2 className="enquiries">
						For any enquiries, please email{' '}
						<a
							className="mailto_link"
							href="mailto:start@nomoss.co?Subject=Team%20Insights:%20Account%20Creation"
							target="_top"
						>
							start@nomoss.co
						</a>
					</h2>
				</div>
			);
		} else if (isNewUser()) {
			view = (
				<div>
					<p style={{ color: '#fff', textAlign: 'center' }}>
						<h1>Welcome to Team Insight</h1>
					</p>
					<h2>Let’s get you started with adding a few teams!</h2>
					<Link
						className="new_team"
						id="new-team__page-button"
						to={{
							pathname: '/teamform',
							state: {
								user: this.props.user,
								data: this.props.data,
							},
						}}
					>
						Add a Team
					</Link>
				</div>
			);
		} else {
			view = (
				<div>
					<div className="company-name-copy">Company:</div>

					<div className="company-picker">
						<Picker
							className="company-picker-style"
							name="organisation_id"
							updateFormData={this.updateFormData}
							range={this.state.organisationsRange}
							variant="outlined"
							defaultValue={this.state.form.organisation_id}
						/>
					</div>

					{this.selectedView()}
				</div>
			);
		}

		return (
			<div className="team-health">
				<Navbar isNewUser={isNewUser} data={data} />
				{view}
			</div>
		);
	}
}

export default TeamHealth;
