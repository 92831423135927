import React, { useState } from 'react';

export const UserContext = React.createContext();

export const UserProvider = ({ children }) => {
	const [user, setUser] = useState(null);
	return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>;
};

export const WithUserContext = Child => {
	const UserComponent = props => (
		<UserContext.Consumer>{userContext => <Child {...userContext} {...props} />}</UserContext.Consumer>
	);
	return UserComponent;
};
