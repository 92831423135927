import React, { PureComponent } from 'react';
import '../Styles/HeatmapCell.scss';
import classNames from 'classnames';
import trendarrow from '../Assets/Img/trendarrow.svg';

class HeatmapCell extends PureComponent {
	state = {
		isOpen: false,
		isInfoModalOpen: false,
		// a 't' variable for storing the current setTimeout id
		t: null
	}

	//looks for score with date of at the current date index in group practice
	score(index) {
		let score = { score: 0 };
		if (!this.props.scoreDatesData) { return score; }
		let date = this.props.scoreDatesData[index];
		if (!date) { return null }
		let scores = this.props.practiceGroup.scores ? this.props.practiceGroup.scores : null;
		if (scores) {
			scores.forEach((_score) => {
				if(_score.date === date) {
					score = _score;
				}
			});
		}
		return score;
	}

	render() {
		let currentScore = this.score(this.props.dateSliderValue).score;
		let previousScore = this.score(this.props.dateSliderValue - 3) ? this.score(this.props.dateSliderValue - 3).score : null;

		return (
			<div className="heatmap-cell__container">
				<div className={this.props.scoreColorClassNames("heatmap-cell", currentScore)}>
					<div className={classNames({'isNotVisible': !this.state.isOpen})}>
						{currentScore}
					</div>
					<img
						className={classNames(
							"trend-arrow",
							{'trend-arrow__none': currentScore === previousScore || previousScore === null},
							{'trend-arrow__up': currentScore > previousScore && previousScore !== null},
							{'trend-arrow__down':  currentScore < previousScore && previousScore !== null},
						)}
						src={trendarrow}
						alt="trend arrow"
					/>
				</div>
			</div>
		);
	}
}

export default HeatmapCell;
