import React, { Component } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import TeamHealth from './Components/TeamHealth';
import TeamView from './Components/TeamView';
import HealthCheckForm from './Components/HealthCheckForm';
import TeamForm from './Components/TeamForm';
import AddCompany from "./Components/AddCompany";
// import News from './Components/News'
// import About from './Components/About'
// import Catalog from './Components/Catalog'
// import DownloadCatalog from './Components/DownloadCatalog'
// import Contact from './Components/Contact'
// import NewsArticle from './Components/NewsArticle'
// import PrivacyPolicy from './Components/PrivacyPolicy'
// import PrivacyPolicyIT from './Components/PrivacyPolicyIT'

class Main extends Component {
	render() {
		return (
			<main style={{ overflow: '-webkit-paged-y' }}>
				<BrowserRouter>
					<Switch>
						<Route
							exact
							path="/"
							render={props => (
								<TeamHealth
									{...props}
									initiateDataGather={this.props.initiateDataGather}
									practices={this.props.practices}
									data={this.props.data}
									scoreDatesData={this.props.scoreDatesData}
									dateSliderValue={this.props.dateSliderValue}
									handleDateSlider={this.props.handleDateSlider}
									isNewUser={this.props.isNewUser}
									user={this.props.user}
									scoreDescription={this.props.scoreDescription}
									scoreColorClassNames={this.props.scoreColorClassNames}
									scoreColor={this.props.scoreColor}
								/>
							)}
						/>
						<Route
							exact
							path="/teamView"
							render={props => (
								<TeamView
									{...props}
									practices={this.props.practices}
									scoreDescription={this.props.scoreDescription}
									scoreColorClassNames={this.props.scoreColorClassNames}
									isNewUser={this.props.isNewUser}
									user={this.props.user}
									setUser={this.props.setUser}
									scoreColor={this.props.scoreColor}
									pageName="TeamView"
								/>
							)}
						/>
						<Route
							exact
							path="/healthCheckForm"
							render={props =>
								this.props.user ? (
									<HealthCheckForm
										{...props}
										data={this.props.data}
										practices={this.props.practices}
										scoreDescription={this.props.scoreDescription}
										scoreColorClassNames={this.props.scoreColorClassNames}
										scoreColor={this.props.scoreColor}
										isNewUser={this.props.isNewUser}
										user={this.props.user}
										setUser={this.props.setUser}
										pageName="HealthCheckForm"
									/>
								) : (
									<Redirect to="/" />
								)
							}
						/>
						<Route
							exact
							path="/teamForm"
							render={props => (
								<TeamForm
									{...props}
									data={this.props.data}
									practices={this.props.practices}
									scoreDescription={this.props.scoreDescription}
									scoreColor={this.props.scoreColor}
									isNewUser={this.props.isNewUser}
									user={this.props.user}
									setUser={this.props.setUser}
									pageName="teamForm"
								/>
							)}
						/>
						<Route
							exact
							path="/admin"
							render={props => (
								<AddCompany
									{...props}
									data={this.props.data}
									practices={this.props.practices}
									scoreDescription={this.props.scoreDescription}
									scoreColor={this.props.scoreColor}
									isNewUser={this.props.isNewUser}
									user={this.props.user}
									setUser={this.props.setUser}
									pageName="admin"
								/>
							)}
						/>
					</Switch>
				</BrowserRouter>
			</main>
		);
	}
}

export default Main;
