import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { login, getProfile, logout } from './Auth';
import '../Styles/Navbar.scss';
import { WithUserContext } from './UserContext';

class Navbar extends Component {
	state = {
		activeClass: false,
		isSideNavHidden: 'hidden',
		user: this.props.user ? this.props.user.email_address : '',
		pass: '',
		scores: '',
	};

	componentDidUpdate(prevProps) {
		if (!prevProps.user && this.props.user) {
			this.setState({
				user: this.props.user.email_address,
			});
		}
	}

	toggleSideNav = () => {
		const css = this.state.isSideNavHidden === 'hidden' ? 'show' : 'hidden';
		this.setState({ isSideNavHidden: css });
		this.toggleMenuButton();
	};

	toggleMenuButton = () => {
		const css = this.state.activeClass === 'active' ? '' : 'active';
		this.setState({ activeClass: css });
	};

	handleChange = event => {
		const { target } = event;
		const { value } = target;
		const { name } = target;

		this.setState({
			[name]: value,
		});
	};

	handleLogin = event => {
		event.preventDefault();

		login({
			email_address: this.state.user,
			password: this.state.pass,
		})
			.then(res => {
				const { id, role_id, user_name, email_address } = getProfile();
				this.props.setUser({
					id,
					role_id,
					user_name,
					email_address,
				});
				this.setState({
					pass: '',
				});
				this.toggleSideNav();
			})
			.catch(err => console.log(`Couldn't log in: ${err}`));
	};

	handleLogout = event => {
		localStorage.removeItem(`${this.props.user.id}-organisation-id`);
		event.preventDefault();
		this.props.setUser(null);
		logout();
		this.setState({
			user: '',
			pass: '',
		});
		this.toggleSideNav();
	};

	render() {
		const { user, pageName, isNewUser, data } = this.props;
		let loginButton;
		if (!user) {
			loginButton = (
				<button className="btn btn-dark" onClick={this.handleLogin}>
					Login
				</button>
			);
		} else {
			loginButton = (
				<button className="btn btn-dark" onClick={this.handleLogout}>
					Logout
				</button>
			);
		}
		return (
			<nav className="Navbar-main" id="nav-id">
				<div className="Navbar-menu__box">
					<ul className="Navbar-menu__list">
						{user && pageName !== 'HealthCheckForm' && !isNewUser() && (
							<div className="logged-in-nav-buttons">
								{user.role_id === 1 && (
								<li className="Navbar-menu__item">
									<Link
										className="new_team"
										to={{
											pathname: '/admin',
											state: {
												user,
												data,
											},
										}}
									>
										Admin
									</Link>
								</li>)}
								<li className="Navbar-menu__item">
									<Link
										className="new_team"
										to={{
											pathname: '/teamform',
											state: {
												user,
												data,
											},
										}}
									>
										Add a Team
									</Link>
								</li>
								{pageName !== 'HealthCheckForm' && (
									<li className="Navbar-menu__item">
										<Link
											className="update_a_team"
											to={{
												pathname: '/healthCheckForm',
												state: {
													user,
													data,
												},
											}}
										>
											Update a Team
										</Link>
									</li>
								)}
							</div>
						)}
					</ul>
				</div>

				<div className="Navbar-logo">
					<Link
						className="logo-div"
						to={{
							pathname: '/',
							state: {
								data,
							},
						}}
					>
						<p className="Navbar-logo__content">Team Insight</p>
					</Link>
				</div>

				<div className="button_box">
					<div className="button">
						<div
							className={this.state.activeClass ? this.state.activeClass : null}
							onClick={this.toggleSideNav}
							id="btn"
						>
							<div className="bar top"></div>
							<div className="bar middle"></div>
							<div className="bar bottom"></div>
						</div>
					</div>
				</div>
				<div className={this.state.isSideNavHidden}>
					<nav>
						<form
							className="TestForm"
							// onSubmit={this.handleSubmit}
						>
							<div>
								<input
									className="user"
									name="user"
									type="text"
									value={this.state.user}
									onChange={this.handleChange}
									placeholder="Username or email"
									autoComplete="off"
								/>
							</div>
							<div>
								<input
									className="password"
									name="pass"
									type="password"
									value={this.state.pass}
									onChange={this.handleChange}
									placeholder="password"
									autoComplete="off"
								/>
							</div>
							{loginButton}
						</form>
					</nav>
				</div>
			</nav>
		);
	}
}
export default WithUserContext(Navbar);
