import React from 'react';
import '../Styles/ThreeFiveThreeCell.scss';
import classNames from 'classnames';
import trendarrow from '../Assets/Img/trendarrow.svg';

const ThreeFiveThreeCell = props => {
	let currentScore = props.score;
	let previousScore = props.previousScore;

	return (
		<div
			className={props.scoreColorClassNames('three-five-three-cell', currentScore)}
			onClick={props.onClick}
			practicegroupid={props.practiceGroupId}
			teamid={props.teamId}
			score={currentScore}
		>
			<div className='isNotVisible'>
				{previousScore}
			</div>
			<img
				className={classNames(
					"trend-arrow",
					{'trend-arrow__none': currentScore === previousScore || previousScore === null},
					{'trend-arrow__up': currentScore > previousScore && previousScore !== null},
					{'trend-arrow__down':  currentScore < previousScore && previousScore !== null},
				)}
				src={trendarrow}
				alt="trend arrow"
			/>
		</div>
	);

	
};

export default ThreeFiveThreeCell;