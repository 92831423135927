import React, { Fragment } from 'react';
import { number, string, bool, func } from 'prop-types';
import '../../Styles/SelectionYesNo.scss';

function YesNoButton(props) {
	const {
		id,
		buttonLabel,
		buttonValue,
		className,
		questionId,
		isChecked,
		htmlFor,
		buttonOnClick,
	} = props;

	return (
		<Fragment>
			<input
				type="radio"
				id={id}
				className={className}
				name={`group-${questionId}`}
				checked={isChecked}
				onChange={() => buttonOnClick(buttonValue)}
			/>
			<label htmlFor={htmlFor} className={className} onClick={() => buttonOnClick(buttonValue)}>
				{buttonLabel}
			</label>
		</Fragment>
	);
}

export default YesNoButton;

YesNoButton.propTypes = {
	id: string,
	buttonLabel: string,
	buttonValue: number,
	className: string,
	questionId: number,
	isChecked: bool,
	htmlFor: string,
	buttonOnClick: func,
};
