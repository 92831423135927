import React, { Component } from 'react';
import '../Styles/TeamForm.scss';
import { Redirect } from 'react-router-dom';
import { authAxios } from './Auth';
import { apiHost } from '../config';
import Button from './FormComponents/Button';
import SingleLineTextField from './FormComponents/SingleLineTextField';
import Navbar from "./Navbar";

class AddCompany extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoaded: false,
			isSubmitted: false,
			form: {}
		};
	}

	componentDidMount() {}

	addUser = e => {
		e.preventDefault();
		if (!this.props.user) {
			return;
		}
		const api = `${apiHost}/api/users/${this.props.user.id}/adduser`;
		authAxios
			.post(api, this.state.form)
			.then(res => {
				if (res.status === 200) {
					this.setState({
						isSubmitted: true,
						user_errors: ['Created.']
					});
				}
			})
			.catch(err => {
				console.log(err);
				this.setState({
					user_errors: [err.response.data.message],
				});
			});

	};

	addCompany = e => {
		e.preventDefault();
		if (!this.state.form.name) {
			this.setState({
				errors: ['Missing Company Name'],
			});
			return;
		}
		if (!this.props.user) {
			return;
		}
		const api = `${apiHost}/api/users/${this.props.user.id}/organisations`;
		authAxios
			.post(api, this.state.form)
			.then(res => {
				if (res.status === 200) {
					this.setState({
						isSubmitted: true,
						errors: ['Created.']
					});
				}
			})
			.catch(err => {
				console.log(err);
				this.setState({
					errors: [err.response.data.message],
				});
			});
	};

	updateFormData = (name, value) => {
		const _form = this.state.form;
		_form[name] = value;
		this.setState({
			form: _form,
		});
	};

	render() {
		if (!this.props.user) {
			return <Redirect to="/" />;
		}

		return (

				<div className="team-form">
					<Navbar pageName={this.props.pageName} isNewUser={this.props.isNewUser} />

					<div>
						<div className="health-form-title">
							<h2>Create a New Company</h2>
						</div>

						<div className="team-question">What is the company name?</div>

						<SingleLineTextField name="name" updateFormData={this.updateFormData} />

						<div>
							<Button onClick={this.addCompany} />
						</div>

						{this.state.errors &&
						this.state.errors.map((error, i) => (
							<div key={i}>
								<li>{error}</li>
							</div>
						))}
					</div>



					<div>
						<div className="health-form-title">
							<h2>Create a New User</h2>
						</div>

						<div className="team-question">What is the user's email?</div>
						<SingleLineTextField name="user_email" updateFormData={this.updateFormData} />
						<div className="team-question">What is the user's name?</div>
						<SingleLineTextField name="user_name" updateFormData={this.updateFormData} />
						<div className="team-question">What is the user's last name?</div>
						<SingleLineTextField name="user_last_name" updateFormData={this.updateFormData} />
						<div className="team-question">What is the user's password?</div>
						<SingleLineTextField name="user_password" updateFormData={this.updateFormData} />

						<div>
							<Button onClick={this.addUser} />
						</div>

						{this.state.user_errors &&
						this.state.user_errors.map((error, i) => (
							<div key={i}>
								<li>{error}</li>
							</div>
						))}
					</div>
			</div>
		);
	}
}

export default AddCompany;
