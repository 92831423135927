import React from 'react'
import '../Styles/Footer.scss';

class Footer extends React.Component {
	render() {
		return(
			<div className="Footer-container">
				<div className="Copyrights-box">
					<p className="Copyrights-box__content">© No Moss All Rights Reserved 2019 | This site has been optimised for <a href="https://www.google.com/chrome/" target="_blank" rel="noopener noreferrer">Chrome</a></p>
				</div>
					<div className="Footer-menu__box">
						<div className="footer_contact">
							<a href="mailto:start@nomoss.co">Contact Us</a>
						</div>
					</div>
			</div>
		)
	}
}

export default Footer;