import React, { Component } from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import '../Styles/MailchimpSubscribe.scss';

// a basic form
const CustomForm = ({ status, message, onValidated }) => {
	let email;
	const submit = () =>
	email &&
	email.value.indexOf("@") > -1 &&
	onValidated({
		EMAIL: email.value,
	});


	return (
		<div
		style={{
			display: "inline-block",
			fontSize: '1rem'
		}}
		>
		<br />
		<input
		style={{
			fontSize: "2em",
			padding: 5,
			width: '320px', }}
		ref={node => (email = node)}
		type="email"
		placeholder="Enter your email address"
		/>
		<br />
		{status === "sending" && <div style={{ color: "#fff", paddingTop:8, fontSize:12  }}>Sending...</div>}
		{status === "error" && (
			<div
			style={{ color: "#fff", paddingTop:8, fontSize:12 }}
			dangerouslySetInnerHTML={{ __html: message }}
			/>
			)}
		{status === "success" && (
			<div
			style={{ color: "#fff", paddingTop:8, fontSize:12  }}
			dangerouslySetInnerHTML={{ __html: message }}
			/>
			)}
		<button
			className="signup-button"
			style={{
				padding: 5,
				marginTop:25, }}
			onClick={submit}>
		Sign Up
		</button>
		</div>
		);
};

class MailchimpSubscribeComp extends Component {
	render() {
		const url =
		"https://nomoss.us20.list-manage.com/subscribe/post?u=4a59610c41e16d42f1bc10cfa&amp;id=fc1d1ba11b";
		return (
			<div>
			<p className="instruction"> Join the beta </p>
			<MailchimpSubscribe
			url={url}
			render={({ subscribe, status, message }) => (
				<CustomForm
				status={status}
				message={message}
				onValidated={formData => subscribe(formData)}
				/>
				)}
			/>
			</div>
			);
	}
}

export default MailchimpSubscribeComp;