import React, { PureComponent } from 'react';
import { func, object, string, number } from 'prop-types';
import { withStyles, createMuiTheme } from '@material-ui/core/styles';
import Slider from '@material-ui/lab/Slider';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';

const styles = {
	root: {
		width: 500,
	},
	slider: {
		padding: '22px 0px',
	},
};

class StepSlider extends PureComponent {
	state = {
		value: 0,
	};

	handleChange = (event, value) => {
		const { handleOnChange } = this.props;
		handleOnChange(value);
	};

	render() {
		const { classes, scoreColor, value, min, max, step } = this.props;
		const theme = createMuiTheme({
			typography: {
				useNextVariants: true,
			},
			palette: {
				primary: {
					// light: will be calculated from palette.primary.main,
					main: scoreColor,
					// dark: will be calculated from palette.primary.main,
					// contrastText: will be calculated to contrast with palette.primary.main
				},
				secondary: {
					light: '#0000ff',
					main: '#0044ff',
					// dark: will be calculated from palette.secondary.main,
					contrastText: '#00cc00',
				},
				// error: will use the default color
			},
		});

		return (
			<MuiThemeProvider theme={theme}>
				<Slider
					classes={{ container: classes.slider }}
					value={value}
					min={min}
					max={max}
					step={step}
					onChange={this.handleChange}
				/>
			</MuiThemeProvider>
		);
	}
}

StepSlider.propTypes = {
	classes: object.isRequired,
	handleOnChange: func,
	scoreColor: string,
	value: number,
	min: number,
	max: number,
	step: number,
};

export default withStyles(styles)(StepSlider);
