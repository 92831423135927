import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginBottom: 20,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  resize:{
  fontSize:16
  },
});

class OutlinedTextFields extends React.Component {
  state = {
    multiline: 'Controlled',
    number: '',
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
    this.props.updateFormData(this.props.name, event.target.value);
  };

  render() {
    const { classes } = this.props;

    return (
      <form className={classes.container} noValidate autoComplete="off">

        <TextField
          id="outlined-bare"
          InputProps={{
            classes: {
              input: classes.resize,
            },
          }}
          value={this.state.age}
          onChange={this.handleChange('number')}
          type="number"
          className={classes.textField}
          defaultValue=""
          margin="normal"
          variant="outlined"
        />

      </form>
    );
  }
}

OutlinedTextFields.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OutlinedTextFields);
