import React from 'react';
import { number, func } from 'prop-types';
import YesNoButton from './FormComponents/YesNoButton';
import { withStyles } from '@material-ui/core/styles';
import '../Styles/SelectionYesNo.scss';

const styles = {
	root: {
		width: 500,
	},
};

function SelectionYesNo(props) {
	const { handleOnChange, positiveValue, negativeValue, neutralValue, questionId, value } = props;

	const handleChange = val => {
		handleOnChange(val);
	};

	return (
		// ESLint will complain that the label should be placed inside the input (label has
		// associated control rule). However, the CSS selector to select the label after the
		// input (input:checked + label) prevents us from putting the tags this way.
		<ul className="button-group">
			<li>
				<YesNoButton
					id={`radio-yes-${questionId}`}
					className="positive"
					questionId={questionId}
					isChecked={value === positiveValue}
					htmlFor={`radio-yes-${questionId}`}
					buttonOnClick={handleChange}
					buttonLabel="Yes"
					buttonValue={positiveValue}
				/>
			</li>
			<li>
				<YesNoButton
					id={`radio-no-${questionId}`}
					className="negative"
					questionId={questionId}
					isChecked={value === negativeValue}
					htmlFor={`radio-no-${questionId}`}
					buttonOnClick={handleChange}
					buttonLabel="No"
					buttonValue={negativeValue}
				/>
			</li>
			<li>
				<YesNoButton
					id={`radio-na-${questionId}`}
					className="neutral"
					questionId={questionId}
					isChecked={value === neutralValue}
					htmlFor={`radio-na-${questionId}`}
					buttonOnClick={handleChange}
					buttonLabel="N/A"
					buttonValue={neutralValue}
				/>
			</li>
		</ul>
	);
}

export default withStyles(styles)(SelectionYesNo);

SelectionYesNo.propTypes = {
	positiveValue: number,
	negativeValue: number,
	neutralValue: number,
	questionId: number,
	value: number,
	handleOnChange: func,
};
