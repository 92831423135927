import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../Styles/TeamView.scss';
import TimeSeries from './TimeSeries';
import PracticeSnapshots from './PracticeSnapshots';
import Navbar from './Navbar';
import { scrollWindowToElement } from 'scroll-element'

class TeamView extends Component {
	componentDidMount() {
		// var scrollId = this.props.history.location.state.practiceIndex == null?'back-button-id':'practice'+this.props.history.location.state.practiceIndex;
		var targetElement = document.getElementById('practice-group'+this.props.history.location.state.practiceGroupId);
		// console.log("tartget element", targetElement);
		if (targetElement != null) {
			targetElement.classList.add("practice-snapshot__highlighted");
			scrollWindowToElement(targetElement, 333, -200);
		} else {
			window.scrollTo(0, 0);
		}
	}

	constructor(props) {
		super(props);
		this.state.teamDataForGraph = this.getDataForTimeSeries(
						this.props.history.location.state.data,
						this.props.history.location.state.teamName,
						this.props.history.location.state.practiceGroupName);
	}

	state = {
		selectedView: "CURRENT SNAPSHOT",
		teamDataForGraph: null,
		data: this.props.history.location.state.data,
		teamData: this.getTeamDataByName(this.props.history.location.state.data,
										this.props.history.location.state.teamName)
	}

	isEmpty = (obj) => {
		for (var key in obj) {
			if (hasOwnProperty.call(obj, key)) return false;
		}
		return true;
	}

	getDataForTimeSeries(data, teamName, practiceGroupName) {
		let teamData = this.getTeamDataByName(data, teamName);
		let practiceGroupData = this.getPracticeGroupDataByName(teamData, practiceGroupName);
		let categories = [];
		let _data = [];
		if (this.isEmpty(practiceGroupData)) {
			practiceGroupData = teamData.practiceGroups[0];
		}
		let scores = [];
		let insights = [];
		for (let i = 0; i < practiceGroupData.scores.length; i++) {
			scores.push(practiceGroupData.scores[i]);
			//TODO: no insights
			insights.push("insights not set up");
			categories.push(i+1);
		}
		_data.push(scores);
		_data.push(insights);
		let timeSeriesData = {
			teamName: teamName,
			practiceGroupName: practiceGroupName,
			categories: categories,
			data: _data
		}
		return timeSeriesData;
	}

	getPracticeGroupDataByName(teamData, practiceGroupName) {
		for (let i = 0; i < teamData.practiceGroups.length; i++) {
			if (teamData.practiceGroups[i].name === practiceGroupName) {
				return teamData.practiceGroups[i];
			}
		}
		return {};
	}

	getTeamDataByName(data, teamName) {
		for(let i = 0; i < data.length; i++) {
			if (data[i].name === teamName) {
				return data[i];
			}
		}
		return {};
	}

	teamComposition() {
		if (this.state.data.location == null) {
			return 'teamComposition undefined';
		}
		if (this.state.data.location.length > 1) {
			var locationsString = '';
			for (var i = 0; i < this.state.data.location.length; i++) {
				locationsString += this.state.data.location[i];
				if (i !== this.state.data.location.length - 1) {
					locationsString += ', ';
				}
			}
			return 'Distributed | ' + locationsString;
		} else {
			return 'Local | ' + this.state.data.location[0];
		}
	}

	teamDistribution() {
		switch (this.state.teamData.is_distributed) {
			case "0":
				return "Co-Located";
			case "1":
				return "Distributed";
			default: return "";
		}
	}

	chooseToggle = (selection) => {
		this.setState({
			selectedView: selection
		});
	}

	viewDescription() {
		switch(this.state.selectedView) {
			case "CURRENT SNAPSHOT":
				return "With the current snapshot you can gain an overview of the current status of your team's health for each practice."
			case "EXPLORE TRENDS":
				return "With trends you can explore the health of your team's practices over time, to identify the triggers that may have improved or weakened your team's health."
			default: return '';
		}
	}

	//TODO: Switch state to prop when information can be passed in
	render() {
		const selectedView = this.state.selectedView;
		let view;
		let product_owner = (this.state.teamData.product_owner == null)?"Unknown":this.state.teamData.product_owner;
		let scrum_master = (this.state.teamData.scrum_master == null)?"Unknown":this.state.teamData.scrum_master;
		switch (selectedView) {
			case 'CURRENT SNAPSHOT':
				view = <PracticeSnapshots
					data={this.state.teamData}
					scoreDescription={this.props.scoreDescription}
					scoreColorClassNames={this.props.scoreColorClassNames}/>;
				break;
			case 'EXPLORE TRENDS':
				view = <TimeSeries
					data={this.state.data}
					teamData={this.state.teamData}
					scoreDescription={this.props.scoreDescription}
					teamDataForGraph={this.state.teamDataForGraph}
					isEmpty={this.isEmpty}
					getTeamDataByName={this.getTeamDataByName}
					getPracticeGroupDataByName={this.getPracticeGroupDataByName}
					getDataForTimeSeries={this.getDataForTimeSeries}/>;
				break;
			default: return '';
		}
		return (
			<div className="TeamView">
				<Navbar 
					pageName = 'TeamView'
					isNewUser={this.props.isNewUser}
					user = {this.props.user}/>
				<div className="back-button" id="back-button-id">
					<Link
						className="button"
						to={{
							pathname: "../",
							state: {
								data: this.state.data
							}
					}}>
						X
					</Link>
					<p className="text">Back to Dashboard</p>
				</div>
				<h1 id="team-data"> <b>TEAM:</b> </h1>
				<h2><b>{this.state.teamData.name}</b></h2>
				<br></br>
				<p id="focus">Current focus: </p><p>{this.state.teamData.current_focus}</p>
				<br></br>
				<br></br>
				<p id="focus">{this.state.teamData.member_count} Members | Product Owner: {product_owner} | Scrum Master: {scrum_master}</p>
				<br></br>
				<p id="focus"> {this.teamDistribution()} | {this.state.teamData.location} </p>
				<br></br>
				{
					/*
					<br></br>
					<TeamViewToggle
						chooseToggle={this.chooseToggle}
						selectedView={this.state.selectedView}
						opts={['CURRENT SNAPSHOT', 'EXPLORE TRENDS']}
					/>
					<br></br>
					<br></br>
					<p>{this.viewDescription()}</p>
					*/
				}
				{view}
			</div>
		);
	}
}

export default TeamView;